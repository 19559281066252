import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { EditorView } from '@codemirror/view';
import { tags as t } from '@lezer/highlight';
import { createTheme } from '@uiw/codemirror-themes';

const myTheme = createTheme({
  theme: 'dark',
  settings: {
    background: '#151515',
    foreground: '#ffffff',
    caret: '#dc0839',
    selection: '#dc083930',
    selectionMatch: '#dc083960',
    lineHighlight: '#dc083920',
  },
  styles: [
    { tag: t.comment, color: '#6a9955' },
    { tag: t.variableName, color: '#9cdcfe' },
    { tag: t.string, color: '#ce9178' },
    { tag: t.number, color: '#b5cea8' },
    { tag: t.keyword, color: '#ff1a4f' },
    { tag: t.operator, color: '#d4d4d4' },
    { tag: t.punctuation, color: '#d4d4d4' },
  ],
});

const CodeInput = ({ setContent, content, readonly }: any) => {
    const onChange = React.useCallback((value: string) => {
        !readonly && setContent(value);
    }, [setContent]);

    return (
        <CodeMirror
            value={content}
            readOnly={readonly}
            height="100%"
            theme={myTheme}
            extensions={[python(), EditorView.lineWrapping]}
            onChange={onChange}
            className="lg:max-h-[calc(100vh-273px)] block w-full text-lg text-white border-none"
        />
    );
};
export default CodeInput;