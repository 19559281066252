import React, { useState } from 'react';
import { genNewAccount } from '../services/api';
import LoadingSpinner from '../components/LoadingSpinner';

const Tools: React.FC = () => {
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const GenerateAddress = () => {
    setIsLoading(true);
    genNewAccount().then((newAccount) => {
      console.log("newAccount =", newAccount);
      setAddress(newAccount.newAccount);
      setIsLoading(false);
    })
  }
  return (
    <div className="tools p-3 flex flex-col">
      <span className='text-2xl'>Generate Account Address</span>
      <div className='p-3 m-3 border-2 border-gray-400'>
        {  isLoading ? <LoadingSpinner/> : <button onClick={GenerateAddress}> Generate </button>}
        <div className='py-3'>
          <span>{address}</span>
        </div>
      </div>
      {/* <h1>Coming Soon</h1>
      <Routes>
        <Route path="hvm-savvy" element={<div>HVM Savvy</div>} />
        <Route path="bend-playground" element={<div>Bend Playground</div>} />
        <Route path="converter" element={<div>Converter</div>} />
      </Routes> */}
    </div>
  );
};

export default Tools;