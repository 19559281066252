import React from 'react';
import BPList from '../components/BPList';

const Home: React.FC = () => {
  return (
    <div className="home">
      <h1>QuantumFusion Dashboard</h1>
      <div className="status-section">
        <h2>System Status</h2>
        <div className="status-grid">
          <div className="status-item">
            <h3>Offchain Lab</h3>
            <p className="status-active">Active</p>
          </div>
          <div className="status-item">
            <h3>Relayer</h3>
            <p className="status-active">Active</p>
          </div>
        </div>
      </div>
      <BPList />
    </div>
  );
};

export default Home;