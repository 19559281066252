export const QRC20Bend = 
`# Contract Name is Qrc20
type Qrc20 = (Contract val)

def Qrc20/View/name():
  return "Qrc20 Token"

def Qrc20/View/symbol():
  return "QF"

def Qrc20/View/decimals():
  return 18
  
def Qrc20/Public/transfer(to, amount):
  return 0

def Qrc20/View/balanceOf(address):
  return 0

def Qrc20/Private/_transfer(from, to, amount):
  return 0
  
def Qrc20/Private/_mint(from, amount):
  return 0

def main():
  return 0
`

export const QRC721Bend = 
`type Qrc721 = (Contract val)

def Qrc721/View/name():
  return "Qrc721 Token"

def Qrc721/View/symbol():
  return "QF"

def Qrc721/View/decimals():
  return 18

def Qrc721/Public/transfer(to, amount):
  return 0

def Qrc721/Public/approve(to, tokenId):
  return 0

def Qrc721/Public/setApprovalForAll(operator, approved):
  return 0

def Qrc721/Public/isApprovedForAll(owner, operator):
  return 0

def Qrc721/View/balanceOf(owner):
  return 0

def Qrc721/View/ownerOf(tokenId):
  return 0

def Qrc721/View/tokenURI(tokenId):
  return ""

def Qrc721/View/_baseURI(tokenId):
  return ""

def Qrc721/View/getApproved(tokenId):
  return 0

def Qrc721/Private/_transfer(from, to, amount):
  return 0

def Qrc721/Private/_mint(from, amount):
  return 0

def main():
  return 0`


export const ComputeBend = 
`# A very simple example of a massively parallel program
# Creates a tree with numbers and then sums all values in parallel

# a binary tree
type MyTree:
  Node { val, ~left, ~right }
  Leaf

# sums all values in a tree
def sum(tree):
  fold tree:
    case MyTree/Node:
      return tree.val + tree.left + tree.right
    case MyTree/Leaf:
      return 0

# generates a binary tree of given depth
def gen(depth):
  bend height=0, val = 1:
    when height < depth:
      tree = MyTree/Node { val: val, left: fork(height+1, 2*val), right: fork(height+1, 2*val+1) }
    else:
      tree = MyTree/Leaf
  return tree

# returns the sum of [1 .. 2^16), truncated to 24 bits
def main:
  return sum(gen(16))`


export const FuncExecutionTest1 = 
`type Staking = (Contract val)

def Staking/View/stake_token():
  return "Cv2unPCVxPQwGrnvzgiK8ntxzvu3FeadcmemH8JNmpFj"
  
def Staking/Public/deposit(address, stake_amount):
  current_amount = 100
  return current_amount + stake_amount

def Staking/Public/withdraw(address, withdraw_amount):
  my_deposit_position = 100
  if my_deposit_position > withdraw_amount:
    return my_deposit_position - withdraw_amount
  else:
    return 0

def Staking/Public/reward(address):
  return 100
  
def main():
  return 0
`

export const StorageTest1 = 
`type TransferTest = (Contract val)

def TransferTest/Public/transfer_qf(from_account, to_account, amount):
  from_account.balance -= amount
  to_account.balance += amount
  return 0

def TransferTest/Public/deposit_qf(my_account, amount):
  my_account.balance += amount
  return 0

def TransferTest/Public/withdraw_qf(my_account, amount):
  if my_account.balance < amount:
    return 0
  else:
    my_account.balance -= amount
    return 1

def main():
  return 0
`


export const StorageTest2= 
`
type StorageTest = (Contract val)

type MyTree:
  Node { val, ~left, ~right }
  Leaf

# sums all values in a tree
def sum(tree):
  fold tree:
    case MyTree/Node:
      return tree.val + tree.left + tree.right
    case MyTree/Leaf:
      return 0

# generates a binary tree of given depth
def gen(depth):
  bend height=0, val = 1:
    when height < depth:
      tree = MyTree/Node { val: val, left: fork(height+1, 2*val), right: fork(height+1, 2*val+1) }
    else:
      tree = MyTree/Leaf
  return tree

def StorageTest/Public/store(store_account, number):
  result = sum(gen(number))
  store_account.data = result
  return store_account.data

def main:
  return 0
`

export const StorageTest3= 
`
type StorageTest = (Contract val)

type MyTree:
  Node { val, ~left, ~right }
  Leaf

# sums all values in a tree
def sum(tree):
  fold tree:
    case MyTree/Node:
      return tree.val + tree.left + tree.right
    case MyTree/Leaf:
      return 0

# generates a binary tree of given depth
def gen(depth):
  bend height=0, val = 1:
    when height < depth:
      tree = MyTree/Node { val: val, left: fork(height+1, 2*val), right: fork(height+1, 2*val+1) }
    else:
      tree = MyTree/Leaf
  return tree

def StorageTest/Public/store(store_account, number):
  result = sum(gen(number))
  store_account.data = result
  return store_account.data

def StorageTest/Public/add(store_account, number):
  result = sum(gen(number))
  store_account.data += result
  return store_account.data

def main:
  return 0
`

export const EXAMPLES = {
  "storage_test.bend": StorageTest1, 
  "storage_test2.bend": StorageTest2, 
  "storage_test3.bend": StorageTest3, 
  "func_exeuction_test1.bend": FuncExecutionTest1, 
  "qrc20.bend": QRC20Bend, 
  "qrc721.bend": QRC721Bend, 
  "compute.bend": ComputeBend
};