import React, { useEffect, useMemo, useState } from 'react';
import { generateAvatarURL } from "@cfx-kit/wallet-avatar";
import BPList from '../components/BPList';
import ClipboardButton from '../components/ClipboardButton';
import TransactionList from '../components/TransactionList';
import ContractDetails from '../components/ContractDeatils';
import { getTransactions } from '../services/api';
import SearchBox from '../components/AccountSearch';

const Explorer: React.FC = () => {
    const [transactionList, setTransactionList] = useState<any>([]);

    useEffect(() => {
        getTransactions("").then((txs) => setTransactionList(txs));
    }, []);

    return (
        <div className='mx-auto max-w-7xl px-1.5sm:px-6 lg:px-8'>
            <div className="home">
                <div className='flex flex-col sm:flex-row w-full items-center gap-2 p-2 justify-between'>
                    <h1 className='font-bold text-white/90 mb-2 sm:mb-0'>
                        <a href='/explorer'>QuantumFusion Explorer</a>
                    </h1>
                    <div className='w-full sm:w-auto'>
                        <SearchBox />
                    </div>
                </div>

                <div className='mt-4'>
                    <TransactionList transactionList={transactionList}/>
                </div>
            </div>
        </div>
    );
};

export default Explorer;