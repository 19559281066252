import SearchBox from "./AccountSearch"

const ExplorerHeader = () => {
    return (
        <div className='flex flex-col sm:flex-row w-full items-center gap-2 p-2 justify-between'>
            <h1 className='font-bold text-white/90 mb-2 sm:mb-0'>
                <a href='/explorer'>QuantumFusion Explorer</a>
            </h1>
            <div className='w-full sm:w-auto'>
                <SearchBox />
            </div>
        </div>
    )
}

export default ExplorerHeader 