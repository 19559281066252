import { useState } from "react";

const ClipboardButton = ({ content, withoutCopyText = false } : { withoutCopyText?: boolean, content?: string }) => {
    
    const [isCopied, setIsCopied] = useState(false);
    const onClickCopy = () => {
        navigator.clipboard.writeText(content ?? "");
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 300);
    }

    return (
        <button data-copy-to-clipboard-target="npm-install-copy-text" className="text-gray-900 dark:text-gray-400 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border" onClick={onClickCopy}>
            <span id="default-message" className={(isCopied ? "hidden" : "") + " inline-flex items-center"}>
                <svg className={`w-3 h-3`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                </svg>
                { !withoutCopyText ? <span className="text-xs font-semibold ms-1.5">Copy</span> : <></> }
            </span>
            <span id="success-message" className={(isCopied ? "" : "hidden") + " inline-flex items-center"}>
                <svg className={`w-3 h-3 text-blue-700 dark:text-blue-500`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                </svg>
                { !withoutCopyText ? <span className="text-xs font-semibold ms-1.5 text-blue-700 dark:text-blue-500">Copied</span> : <></> }
            </span>
        </button>
    )
}

export default ClipboardButton;