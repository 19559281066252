import React, { useState, Dispatch, SetStateAction } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { EditorView } from '@codemirror/view';
import { tags as t } from '@lezer/highlight';
import { createTheme } from '@uiw/codemirror-themes';
import './docs.css';

interface SidebarProps {
    setActiveSection: Dispatch<SetStateAction<string>>;
    isOpen: boolean;
    closeSidebar: () => void;
  }

// Define the theme
const myTheme = createTheme({
    theme: 'dark',
    settings: {
      background: '#151515',
      foreground: '#ffffff',
      caret: '#dc0839',
      selection: '#dc083930',  // Increased opacity for better visibility
      selectionMatch: '#dc083960',  // Increased opacity for better visibility
      lineHighlight: '#dc083920',
    },
    styles: [
      { tag: t.comment, color: '#6a9955' },
      { tag: t.variableName, color: '#9cdcfe' },
      { tag: t.string, color: '#ce9178' },
      { tag: t.number, color: '#b5cea8' },
      { tag: t.keyword, color: '#ff1a4f' },
      { tag: t.operator, color: '#d4d4d4' },
      { tag: t.punctuation, color: '#d4d4d4' },
    ],
  });

const CodeBlock: React.FC<{ code: string }> = ({ code }) => {
    return (
      <CodeMirror
        value={code}
        height="auto"
        theme={myTheme}
        extensions={[python(), EditorView.lineWrapping]}
        editable={false}
        className="max-h-[calc(40vh-160px)] block w-full text-lg text-white border-none"
      />
    );
  };

  interface SidebarProps {
    setActiveSection: Dispatch<SetStateAction<string>>;
    isOpen: boolean;
    closeSidebar: () => void;
    activeSection: string;
  }
  
  const Docs: React.FC = () => {
    const [activeSection, setActiveSection] = useState('playground');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    return (
      <div className="documentation text-white/85">
        <button className="menu-toggle" onClick={toggleSidebar}>
          ☰ Menu
        </button>
        <Sidebar 
          setActiveSection={setActiveSection} 
          isOpen={isSidebarOpen}
          closeSidebar={() => setIsSidebarOpen(false)}
          activeSection={activeSection}
        />
        <MainContent activeSection={activeSection} />
      </div>
    );
  };
  
  const Sidebar: React.FC<SidebarProps & { activeSection: string }> = ({ setActiveSection, isOpen, closeSidebar, activeSection }) => {
    const handleClick = (section: string) => {
      setActiveSection(section);
      closeSidebar();
    };
  
    return (
      <nav className={`sidebar bg-primary-400/80 ${isOpen ? 'active' : ''}`}>
        <h2>Contents</h2>
        <ul>
          {/* <li><button onClick={() => handleClick('introduction')}>1. Introduction</button></li> */}
          <li><div className={`p-2 rounded cursor-pointer ${activeSection === 'playground' ? 'bg-accent-500 text-white' : ''}`} onClick={() => handleClick('playground')}>1. Playground Instructions</div></li>
          <li><div className={`p-2 rounded cursor-pointer ${activeSection === 'bend' ? 'bg-accent-500 text-white' : ''}`} onClick={() => handleClick('bend')}>2. Introduction to Bend</div></li>
          <li><div className={`p-2 rounded cursor-pointer ${activeSection === 'qfbend' ? 'bg-accent-500 text-white' : ''}`} onClick={() => handleClick('qfbend')}>3. qfBend Smart Contracts</div></li>
        </ul>
      </nav>
    );
  };

  interface MainContentProps {
    activeSection: string;
  }
  
  const MainContent: React.FC<MainContentProps> = ({ activeSection }) => {
    const sections = {
    //   introduction: <Introduction />,
      playground: <PlaygroundInstructions />,
      bend: <BendIntroduction />,
      qfbend: <QfBendContracts />
    };
  
    return (
      <main className="main-content p-8 bg-white/5">
        {sections[activeSection as keyof typeof sections]}
      </main>
    );
  };

// const Introduction: React.FC = () => (
//   <section>
//     <h1>1. Introduction to QuantumFusion</h1>
//     <p>QuantumFusion represents a paradigm shift in blockchain technology, leveraging the power of massively parallel computing to create a scalable and efficient smart contract platform. By harnessing the full potential of modern multi-core processors and GPUs, QuantumFusion aims to solve the scalability issues that have long plagued traditional blockchain platforms.</p>
    
//     <h2>1.1 Core Components</h2>
//     <p>At the heart of the QuantumFusion system are two key components:</p>
//     <ol>
//       <li>
//         <strong>HVM2 (Higher-Order Virtual Machine 2)</strong>
//         <p>HVM2 serves as the execution layer for QuantumFusion, enabling parallel processing of smart contracts. It's built on the foundation of Interaction Combinators, a model of computation that inherently supports massive parallelism. HVM2 allows for the efficient execution of complex computations across multiple cores or GPUs, significantly improving the throughput and performance of smart contract operations.</p>
//       </li>
//       <li>
//         <strong>Bend</strong>
//         <p>Bend is a high-level programming language specifically designed for writing parallelizable smart contracts. It combines the ease of use found in languages like Python with the functional programming paradigms of languages like Haskell. Bend's syntax and constructs are tailored to express parallel computations naturally, allowing developers to write efficient, scalable smart contracts without explicitly managing threads or synchronization primitives.</p>
//       </li>
//     </ol>

//     <h2>1.2 Key Advantages of QuantumFusion</h2>
//     <ul>
//       <li><strong>Scalability:</strong> By leveraging parallel computing, QuantumFusion can process multiple transactions and smart contract operations simultaneously, greatly increasing the overall throughput of the blockchain.</li>
//       <li><strong>Efficiency:</strong> The parallelization of computations leads to more efficient use of computational resources, potentially reducing energy consumption and transaction costs.</li>
//       <li><strong>Programmability:</strong> Bend provides a user-friendly yet powerful language for writing smart contracts, making it easier for developers to create complex, parallelizable applications.</li>
//       <li><strong>Performance:</strong> With the ability to utilize multi-core CPUs and GPUs, QuantumFusion can achieve performance levels that are orders of magnitude higher than traditional sequential blockchain systems.</li>
//     </ul>

//     <h2>1.3 Potential Applications</h2>
//     <p>The capabilities of QuantumFusion open up new possibilities for blockchain applications, particularly in areas that require high computational power:</p>
//     <ul>
//       <li><strong>Decentralized Finance (DeFi):</strong> Complex financial models and high-frequency trading systems can be implemented with improved efficiency.</li>
//       <li><strong>Artificial Intelligence and Machine Learning:</strong> Training and inference of AI models could potentially be performed on-chain, opening new frontiers for decentralized AI applications.</li>
//       <li><strong>Scientific Simulations:</strong> Computationally intensive simulations in fields like physics, chemistry, or climate science could leverage the parallel processing capabilities of QuantumFusion.</li>
//       <li><strong>Gaming and Virtual Worlds:</strong> The increased computational power could support more complex game mechanics and larger-scale virtual environments directly on the blockchain.</li>
//     </ul>

//     <h2>1.4 The QuantumFusion Ecosystem</h2>
//     <p>QuantumFusion is more than just a blockchain platform; it's an ecosystem designed to support developers, researchers, and businesses in building the next generation of decentralized applications. The ecosystem includes:</p>
//     <ul>
//       <li><strong>QuantumFusion Playground:</strong> An interactive development environment for writing, testing, and deploying smart contracts.</li>
//       <li><strong>Developer Tools and SDKs:</strong> A comprehensive set of tools to assist in the development and integration of QuantumFusion applications.</li>
//       <li><strong>Community and Documentation:</strong> Extensive documentation, tutorials, and an active community to support developers in their journey with QuantumFusion.</li>
//     </ul>

//     <p>In the following sections, we'll dive deeper into the QuantumFusion Playground, the Bend programming language, and the specifics of writing smart contracts for the QuantumFusion platform.</p>
//   </section>
// );

const PlaygroundInstructions: React.FC = () => (
  <section className="pb-20">
    <h1>1. QuantumFusion Playground Instructions</h1>
    
    <h2>1.1 Overview</h2>
    <p>The QuantumFusion Playground is a web-based integrated development environment (IDE) designed specifically for writing, testing, and deploying smart contracts on the QuantumFusion platform. It provides an interface that allows developers to interact with the QuantumFusion blockchain directly from their web browser.</p>

    <h2>1.2 Playground Interface</h2>
    <h3>1.2.1 Layout</h3>
    <p>The Playground interface is divided into several key areas:</p>
    <ul>
      <li><strong>Left Side - Code Editor:</strong>
        <ul>
          <li>A text editor for inputting and editing qfBend code.</li>
          <li>A dropdown menu above the editor for selecting example contracts.</li>
          <li>Syntax highlighting for easier code reading and writing.</li>
        </ul>
      </li>
      <li><strong>Right Side - Console and Controls:</strong>
        <ul>
          <li>Console output displays compilation results, deployment status, and execution outputs.</li>
          <li><b>"Compile"</b> button: Triggers code compilation and error checking.</li>
          <li><b>"Deploy"</b> button: Initiates the contract deployment process.</li>
        </ul>
      </li>
      <li><strong>Below Editor:</strong>
        <ul>
            <li>Expandable components to inspect HVM2 and ABI result compilation</li>
        </ul>
      </li>
    </ul>

    <h2>1.3 Using the Playground</h2>
    <h3>1.3.1 Writing Code</h3>
    <p>To begin writing your smart contract:</p>
    <ol>
      <li>Click on the code editor on the left side of the screen.</li>
      <li>Start typing your qfBend code.</li>
      <li>If you're new to qfBend, consider selecting an example contract from the dropdown menu above or dive deeper into documentation.</li>
    </ol>

    <h3>1.4.2 Compiling</h3>
    <p>To compile your code:</p>
    <ol>
      <li>Click the "Compile" button above the console.</li>
      <li>Watch the console for compilation results. It will show:
        <ul>
          <li>Success messages if the compilation is successful.</li>
          <li>Error messages with details if there are any issues in your code.</li>
        </ul>
      </li>
      <li>If there are errors, review the error messages, make necessary corrections, and compile again.</li>
    </ol>

    <h3>1.4.3 Deploying</h3>
<p>Once your code compiles successfully:</p>
<ol>
  <li>Click the "Deploy" button.</li>
  <li>The console will show the progress of the deployment process.</li>
  <li>Upon successful deployment, you'll see messages like:
    <CodeBlock code={`Deployed successfully to account: 5EnjcxSd41b4dxfx9ULuMCa...
Transaction Hash: 0xa7e79897496d8cd27a3329806...`} />
  </li>
  <li>These are clickable links that will take you to the transaction details or deployed contract in the QuantumFusion explorer.</li>
</ol>

<h3>1.4.4 Interacting with Deployed Contracts</h3>
<p>There are multiple ways to interact with deployed contracts:</p>

<h4>1. From Deployment Links</h4>
<p>After deploying, click on the provided links to access the contract in the QuantumFusion explorer.</p>

<h4>2. Through the QuantumFusion Explorer</h4>
<ol>
  <li>Navigate to the QuantumFusion explorer.</li>
  <li>Browse through existing deployments or search for a specific contract.</li>
  <li>On the transaction details page, you'll find:
    <ul>
      <li>Transaction Hash</li>
      <li>Status</li>
      <li>Timestamp</li>
      <li>From and To addresses</li>
      <li>ZK Proof and Verification Result</li>
      <li>Details (e.g., "Contract Deployment Transaction")</li>
    </ul>
  </li>
  <li>If it's a contract deployment transaction, you'll see a "Run main" button to execute the main function and inspect its result.</li>
  <li>For execution transactions, the Details field will show the executed function and parameters, e.g., `TransferTest/Public/withdraw_qf("9jJ8wu1z7mwQYL6JAV5CdcaXw4tLa6tUAz7BrsoGbZuv",10)`</li>
</ol>

<h4>3. On the Contract Page</h4>
<p>The contract page in the explorer offers two main sections:</p>
<ul>
  <li><strong>Transactions:</strong> Shows a list of transactions that interacted with this contract.</li>
  <li><strong>Contract:</strong> Displays contract details and allows interaction. It has three tabs:
    <ol>
      <li><strong>Code:</strong> Shows the deployed contract's code, HVM, and ABI. Includes a "Run main" button.</li>
      <li><strong>Read Contract:</strong> Interact with read-only functions (view functions). May be empty if the contract has no read-only functions.</li>
      <li><strong>Write Contract:</strong> Shows available write functions and input fields to interact with them.</li>
    </ol>
  </li>
</ul>

<p>To interact with a contract:</p>
<ol>
  <li>Navigate to the appropriate tab (Read Contract or Write Contract).</li>
  <li>Select the function you want to call.</li>
  <li>Enter any required parameters.</li>
  <li>Click "Execute" for write functions or "Call" for read-only functions.</li>
  <li>The result or transaction details will be displayed after execution.</li>
</ol>

<p>
  The QuantumFusion Playground is a work-in-progress tool that offers a glimpse into the current state of our development efforts. It allows you to experiment with smart contract development, testing, and deployment on the QuantumFusion platform. However, please note that it is under heavy development, and you should expect frequent changes, potential bugs, and occasional downtimes. The state of the playground may be wiped clean without prior notice, so please do not rely on it for permanent storage of your work. Despite these limitations, the playground provides valuable insights into the evolving capabilities of the QuantumFusion ecosystem.
</p>
  </section>
);

// const BendIntroduction: React.FC = () => (
//     <section>
//       <h1>2. Introduction to Bend</h1>
      
//       <p>Bend is a high-level, massively parallel programming language designed specifically for the QuantumFusion platform. It combines the ease of use found in languages like Python with the functional programming paradigms of languages like Haskell, while introducing unique constructs that enable efficient parallel computation.</p>
  
//       <h2>2.1 Key Features of Bend</h2>
//       <ul>
//         <li><strong>Automatic Parallelization:</strong> Bend parallelizes code without requiring explicit thread management.</li>
//         <li><strong>Immutability:</strong> Variables in Bend are immutable by default, promoting functional programming patterns.</li>
//         <li><strong>Recursive Datatypes:</strong> Powerful support for complex data structures through recursive datatypes.</li>
//         <li><strong>Pattern Matching:</strong> Extensive pattern matching capabilities for handling different cases in datatypes.</li>
//         <li><strong>Folds and Bends:</strong> Special constructs for consuming and generating recursive datatypes, replacing traditional loops.</li>
//         <li><strong>Native Number Types:</strong> Built-in support for `u24`, `i24`, and `f24` for unsigned integers, signed integers, and floating-point numbers respectively.</li>
//       </ul>
  
//       <h2>2.2 Basic Syntax and Concepts</h2>
  
//       <h3>2.2.1 Functions</h3>
//       <p>In Bend, functions are pure: they receive input and return output without side effects.</p>
//       <CodeBlock code={`def am_i_old(age):
//     if age < 18:
//       return "you're a kid"
//     else:
//       return "you're an adult"
  
//   def main():
//     return am_i_old(32)`} />
  
//       <h3>2.2.2 Objects</h3>
//       <p>Unlike Python's classes, Bend uses simple object definitions:</p>
//       <CodeBlock  code={`object V2 { x, y }
  
//   def distance(a, b):
//     open V2: a
//     open V2: b
//     dx = b.x - a.x
//     dy = b.y - a.y
//     return (dx * dx + dy * dy) ** 0.5
  
//   def main():
//     return distance(V2 { x: 10.0, y: 10.0 }, V2 { x: 20.0, y: 20.0 })`} />
//       <p>Note the use of `open` to access object fields. This is related to Bend's affine nature.</p>
  
//       <h3>2.2.3 Datatypes</h3>
//       <p>Bend supports powerful algebraic datatypes:</p>
//       <CodeBlock code={`type Shape:
//     Circle { radius }
//     Rectangle { width, height }
  
//   def area(shape):
//     match shape:
//       case Shape/Circle:
//         return 3.14 * shape.radius ** 2.0
//       case Shape/Rectangle:
//         return shape.width * shape.height
  
//   def main:
//     return area(Shape/Circle { radius: 10.0 })`} />
  
//       <h3>2.2.4 Lists</h3>
//       <p>Lists in Bend are implemented as recursive datatypes:</p>
//       <CodeBlock code={`type List:
//     Nil
//     Cons { head, ~tail }
  
//   def main:
//     my_list = [1, 2, 3]  # Syntactic sugar for List structure
//     match my_list:
//       case List/Cons:
//         return my_list.head
//       case List/Nil:
//         return 0`} />
  
//       <h2>2.3 Advanced Concepts</h2>
  
//       <h3>2.3.1 Immutability and Functional Programming</h3>
//       <p>Bend enforces immutability, which can be challenging for those used to imperative programming. Instead of loops and mutable variables, Bend encourages recursive and functional approaches:</p>
//       <CodeBlock code={`def is_even(x):
//     if x % 2 == 0:
//       return "even"
//     else:
//       return "odd"
  
//   def main:
//     return is_even(7)`} />
  
//       <h3>2.3.2 Folds and Bends</h3>
//       <p>Folds and bends are powerful constructs for working with recursive datatypes:</p>
//       <CodeBlock code={`def sum(tree):
//     fold tree:
//       case Tree/Node:
//         return tree.left + tree.right
//       case Tree/Leaf:
//         return tree.value
  
//   def main:
//     tree = ![![!1, !2],![!3, !4]]
//     return sum(tree)`} />
  
//       <p>Bends are used for generating recursive structures:</p>
//       <CodeBlock code={`def main():
//     bend x = 0:
//       when x < 3:
//         tree = ![fork(x + 1), fork(x + 1)]
//       else:
//         tree = !7
//     return tree`} />
  
//       <h2>2.4 Parallel Programming in Bend</h2>
//       <p>Bend's power lies in its ability to automatically parallelize computations. The key is to write algorithms that aren't inherently sequential:</p>
//       <CodeBlock code={`def main():
//     bend d = 0, i = 0:
//       when d < 28:
//         sum = fork(d+1, i*2+0) + fork(d+1, i*2+1)
//       else:
//         sum = i
//     return sum`} />
//       <p>This example demonstrates a parallel summation algorithm that Bend can execute efficiently across multiple cores or GPU threads.</p>
  
//       <h2>2.5 Performance Considerations</h2>
//       <p>Bend's performance can vary significantly based on the hardware and compilation method:</p>
//       <ul>
//         <li>Single-core performance (e.g., `bend run-rs`) is typically the slowest.</li>
//         <li>Multi-core CPU performance (e.g., `bend run` or `bend run-c`) can offer significant speedups.</li>
//         <li>GPU execution (e.g., `bend run-cu`) can provide massive parallelism for suitable algorithms.</li>
//       </ul>
//       <p>Always use the `-s` flag to see performance metrics and ensure your algorithm is parallelizing effectively.</p>
  
//       <h2>2.6 Best Practices</h2>
//       <ul>
//         <li>Embrace immutability and functional programming paradigms.</li>
//         <li>Use folds and bends for complex operations on recursive datatypes.</li>
//         <li>Design algorithms with parallelism in mind, avoiding unnecessarily sequential operations.</li>
//         <li>Utilize Bend's automatic parallelization by expressing computations as independent operations where possible.</li>
//         <li>Be mindful of memory usage, especially when working with large datasets or deep recursive structures.</li>
//       </ul>
//     </section>
//   );
  
const BendIntroduction: React.FC = () => (
    <section>
            <h1 className="text-3xl font-bold">Introduction to Bend</h1>
    
    <p>
      Bend is a high-level, massively parallel programming language designed by <a href="https://higherorderco.com/">HigherOrderCO</a>. It combines Python-like ease of use with Haskell-inspired functional programming paradigms, introducing unique constructs for efficient parallel computation.
    </p>
<h2 id="hello-world">Hello, World!</h2>
<p>As we said, Bend <em>feels</em> like Python - in some ways. It is high-level, you can
easily create objects and lists, there are ifs and loops. Yet, it is different:
there is some Haskell in it, in the sense algebraic datatypes, pattern-matching
and recursion play an important role. This is how its <code>"Hello, world!"</code> looks:</p>
<CodeBlock code={`def main():
  return "Hello, world!"`} />
<p>Wait - there is something strange there. Why <code>return</code>, not <code>print</code>? Well, <em>for
now</em> (you&#39;ll read these words a lot), Bend doesn&#39;t have IO. We plan on
introducing it very soon! So, <em>for now</em>, all you can do is perform computations,
and see results.</p>
<p>If all goes well, you should see <code>"Hello, world!"</code>. The <code>bend run-rs</code> command uses
the reference interpreter, which is slow. In a few moments, we&#39;ll teach you how
to run your code in parallel, on both CPUs and GPUs. For now, let&#39;s learn some
fundamentals!</p>
<h2 id="basic-functions-and-datatypes">Basic Functions and Datatypes</h2>
<p>In Bend, functions are pure: they receive something, and they return something.
That&#39;s all. Here is a function that tells you how old you are:</p>
<CodeBlock code={`def am_i_old(age):
  if age &lt; 18:
    return "you&#39;re a kid"
  else:
    return "you&#39;re an adult"

def main():
  return am_i_old(32)`} />
<p>That is simple enough, isn&#39;t it? Here is one that returns the distance between
two points:</p>
<CodeBlock code={`def distance(ax, ay, bx, by):
  dx = bx - ax
  dy = by - ay
  return (dx * dx + dy * dy) ** 0.5

def main():
  return distance(10.0, 10.0, 20.0, 20.0)`} />
<p>This isn&#39;t so pretty. Could we use tuples instead? Yes:</p>
<CodeBlock code={`def distance(a, b):
  (ax, ay) = a
  (bx, by) = b
  dx = bx - ax
  dy = by - ay
  return (dx * dx + dy * dy) ** 0.5

def main():
  return distance((10.0, 10.0), (20.0, 20.0))`} />
<p>So far, this does look like Python, doesn&#39;t it? What about objects? Well - here,
there is a difference. In Python, we have classes. In Bend, we just have the
objects themselves. This is how we create a 2D vector:</p>
<CodeBlock code={`object V2 { x, y }

def distance(a, b):
  open V2: a
  open V2: b
  dx = b.x - a.x
  dy = b.y - a.y
  return (dx * dx + dy * dy) ** 0.5

def main():
  return distance(V2 { x: 10.0, y: 10.0 }, V2 { x: 20.0, y: 20.0 })`} />
<p className="mt-5">This doesn&#39;t look too different, does it? What is that <code>open</code> thing, though? It
just tells Bend to <em>consume</em> the vector, <code>a</code>, "splitting" it into its
components, <code>a.x</code> and <code>a.y</code>. Is that really necessary? Actually, no - not
really. But, <em>for now</em>, it is. This has to do with the fact Bend is an affine
language, which... well, let&#39;s not get into that. For now, just remember we
need <code>open</code> to access fields.</p>
<p>Bend comes with 3 built-in numeric types: <code>u24</code>, <code>i24</code>, <code>f24</code>. That&#39;s quite
small, we admit. Soon, we&#39;ll have larger types. For now, that&#39;s what we got.
The <code>u24</code> type is written like <code>123</code> or <code>0xF</code>. The <code>i24</code> type requires a sign,
as in, <code>+7</code> or <code>-7</code>. The <code>f24</code> type uses <code>.</code>, like <code>3.14</code>.</p>
<p>Other than tuples, Bend has another, very general, way to encode data:
datatypes! These are just "objects with tags". A classic example of this is a
"shape", which can be either a circle or a rectangle. It is defined like this:</p>
<CodeBlock code={`type Shape:
  Circle { radius }
  Rectangle { width, height }

def area(shape):
  match shape:
    case Shape/Circle:
      return 3.14 * shape.radius ** 2.0
    case Shape/Rectangle:
      return shape.width * shape.height

def main:
  return area(Shape/Circle { radius: 10.0 })`} />
<p>In this example, <code>Shape</code> is a datatype with two variants: <code>Circle</code> and
<code>Rectangle</code>. The <code>area</code> function uses pattern matching to handle each variant
appropriately. Just like objects need <code>open</code>, datatypes need <code>match</code>, which
give us access to fields in each respective case.</p>
<p>Datatypes are very general. From matrices, to JSON, to quadtrees, every type of
data can be represented as a datatype (I mean, that&#39;s the name!). In fact,
lists - which, on Python, are actually stored as arrays - are represented using
datatypes on Bend. Specifically, the type:</p>
<CodeBlock code={`type List:
  Nil
  Cons { head, ~tail }`} />
<p>Here, the <code>Nil</code> variant represents an empty list, and the <code>Cons</code> variant
represents a concatenation between an element (<code>head</code>) and another list
(<code>tail</code>). That way, the <code>[1,2,3]</code> list could be written as:</p>
<CodeBlock code={`def main:
  my_list = List/Cons { head: 1, tail: List/Cons { head: 2, tail: List/Cons { head: 3, tail: List/Nil }}}
  return my_list`} />
<p>Obviously - that&#39;s terrible. So, you can write just instead:</p>
<CodeBlock code={`def main:
  my_list = [1, 2, 3]
  return my_list`} />
<p>Which is decent. But while it is written the same as in Python, it is important
to understand it is just the <code>List</code> datatype, which means we can operate on it
using the <code>match</code> notation. For example:</p>
<CodeBlock code={`def main:
  my_list = [1, 2, 3]
  match my_list:
    case List/Cons:
      return my_list.head
    case List/Nil:
      return 0`} />
<p>Will return <code>1</code>, which is the first element.</p>
<blockquote>
<p><strong><em>NOTE:</em></strong> Despite creating lists with <code>[</code> <code>]</code>, the syntax used for accessing values as in <code>type[key]</code> is actually related to the <code>Map</code> built-in type.</p>
</blockquote>
<p>We also have a syntax sugar for strings in Bend, which is just a list of <code>u24</code>
characters (UTF-16 encoded). The <code>"Hello, world!"</code> type we&#39;ve seen used it!</p>
<blockquote>
<p><strong><em>NOTE:</em></strong> The actual type used for strings is <code>String</code>, which has <code>String/Cons</code> and <code>String/Nil</code> just like <code>List</code> does.</p>
</blockquote>
<p>Bend also has inline functions, which work just like Python:</p>
<CodeBlock code={`def main:
  mul_2 = lambda x: x * 2
  return mul_2(7)`} />
<p>Except without the annoying syntax restrictions. You can also shorten it as <code>λ</code>,
if you can somehow type that.</p>
<p>You can also match on native numbers (<code>u24</code>) using the <code>switch</code> statement:</p>
<CodeBlock code={`def slow_mul2(n):
  switch n:
    case 0:
      return 0
    case _:
      return 2 + slow_mul2(n-1)`} />
<p>The <code>if-else</code> syntax is a third option to branch, other than <code>match</code> and
<code>switch</code>. It expects a <code>u24</code> (<code>1</code> for <code>true</code> and <code>0</code> for <code>false</code>):</p>
<CodeBlock code={`def is_even(n):
  if n % 2 == 0:
    return 1
  else:
    return 0`} />
<p><em>note - some types, like tuples, aren&#39;t being pretty-printed correctly after
computation. this will be fixed in the next days (TM)</em></p>
<h2 id="the-dreaded-immutability">The Dreaded Immutability</h2>
<p>Finally, let&#39;s get straight to the fun part: how do we implement parallel
algorithms with Bend? Just kidding. Before we get there, let&#39;s talk about loops.
You might have noticed we have avoided them so far. That wasn&#39;t by accident.
There is an important aspect on which Bend diverges from Python, and aligns with
Haskell: <strong>variables are immutable</strong>. Not "by default". They just <strong>are</strong>. For
example, in Bend, we&#39;re not allowed to write:</p>
<CodeBlock code={`def parity(x):
  result = "odd"
  if x % 2 == 0:
    result = "even"
  return result`} />
<p>... because that would mutate the <code>result</code> variable. Instead, we should write:</p>
<CodeBlock code={`def is_even(x):
  if x % 2 == 0:
    return "even"
  else:
    return "odd"

def main:
  return is_even(7)`} />
<p>Which is immutable. If that sounds annoying, that&#39;s because <strong>it is</strong>. Don&#39;t
let anyone tell you otherwise. We are aware of that, and we have many ideas on
how to improve this, making Bend feel even more Python-like. For now, we have to
live with it. But, wait... if variables are immutable... how do we even do
loops? For example:</p>
<CodeBlock code={`def sum(x):
  total = 0
  for i in range(10)
    total += i
  return total`} />
<p>Here, the entire way the algorithm works is by mutating the <code>total</code> variable.
Without mutability, loops don&#39;t make sense. The good news is Bend has <em>something
else</em> that is equally as - actually, more - powerful. And learning it is really
worth your time. Let&#39;s do it!</p>
<h2 id="folds-and-bends">Folds and Bends</h2>
<h3 id="recursive-datatypes">Recursive Datatypes</h3>
<p>Let&#39;s start by implementing a recursive datatype in Bend:</p>
<CodeBlock code={`type Tree:
  Node { ~left, ~right }
  Leaf { value }`} />
<p>This defines a binary tree, with elements on leaves. Here, <code>~</code> flags a field as
<em>recursive</em>. For example, the tree:</p>
<pre className="whitespace-pre-wrap break-all max-w-xs">
  <code>{`  __/\\__
 /\\     /\\
1  2   3  4`}</code>
</pre>
<p>Could be represented as:</p>
<CodeBlock code={`tree = Tree/Node {
  lft: Tree/Node { left: Tree/Leaf { val: 1 }, right: Tree/Leaf { val: 2 } },
  rgt: Tree/Node { left: Tree/Leaf { val: 3 }, right: Tree/Leaf { val: 4 } }
}`} />
<p>Binary trees are so useful in Bend that this type is already pre-defined in the
language and has its own dedicated syntax:</p>
<CodeBlock code={`# ![a, b] =&gt; Equivalent to Tree/Node { left: a, right: b }
# !x      =&gt; Equivalent to Tree/Leaf { value: x }
tree = ![![!1, !2],![!3, !4]]`} />
<h3 id="fold-consuming-recursive-datatypes">Fold: consuming recursive datatypes</h3>
<p>Now, here&#39;s a question: how do we <em>sum</em> the elements of a tree? In Python, we
could just use a loop. In Bend, we don&#39;t have loops. Fortunately, there is
another construct we can use: it&#39;s called <code>fold</code>, and it works like a <em>search
and replace</em> for datatypes. For example, consider the code below:</p>
<CodeBlock code={`def sum(tree):
  fold tree:
    case Tree/Node:
      return tree.left + tree.right
    case Tree/Leaf:
      return tree.value

def main:
  tree = ![![!1, !2],![!3, !4]]
  return sum(tree)`} />
<p>It accomplishes the task by replacing every <code>Tree/Node {'{ left, right }'}</code> by <code>left + right</code>, and replacing every <code>Tree/Leaf</code> by <code>value</code>. As a result, the entire "tree of
values" is turned into a "tree of additions", and it evaluates as follows:</p>
<CodeBlock code={`nums = ((1 + 2) + (3 + 4))
nums = (3 + 7)
nums = 10`} />
<p>Now, this may look limiting, but it actually isn&#39;t. Folds are known for being
universal: <em>any algorithm that can be implemented with a loop, can be
implemented with a fold</em>. So, we can do much more than just compute an
"aggregated value". Suppose we wanted, for example, to transform every element
into a tuple of <code>(index,value)</code>, returning a new tree. Here&#39;s how to do it:</p>
<CodeBlock code={`def enum(tree):
  idx = 0
  fold tree with idx:
    case Tree/Node:
      return ![tree.left(idx * 2 + 0), tree.right(idx * 2 + 1)]
    case Tree/Leaf:
      return !(idx, tree.value)

def main:
  tree = ![![!1, !2],![!3, !4]]
  return enum(tree)`} />
<p className="mt-5">Compared to the <code>sum</code> algorithm, 3 important things changed:</p>
<ol>
<li><p>We initialize a state, <code>idx</code>, as <code>0</code>.</p>
</li>
<li><p>We pass new states down as <code>tree.xyz(new_idx)</code></p>
</li>
<li><p>The base case receives the final state: the element index</p>
</li>
</ol>
<p>So, in the end, we&#39;ll have computed a copy of the original tree, except that
every element has now became a tuple of index and value.</p>
<p>Now, please take a moment to think about this fact: <strong>everything can be computed
with a fold.</strong> This idea often takes some time to get used to, but, once you do,
it is really liberating, and will let you write better algorithms. As an
exercise, use <code>fold</code> to implement a "reverse" algorithm for lists:</p>
<CodeBlock code={`def reverse(list):
  # exercise
  ?

def main:
  return reverse([1,2,3])`} />
<h2 id="bend-generating-recursive-datatypes">Bend: generating recursive datatypes</h2>
<p>Bending is the opposite of folding. Whatever <code>fold</code> consumes, <code>bend</code> creates.
The idea is that, by defining an <em>initial state</em> and a <em>halting condition</em>, we
can "grow" a recursive structure, layer by layer, until the condition is met.
For example, consider the code below:</p>
<CodeBlock code={`def main():
  bend x = 0:
    when x &lt; 3:
      tree = ![fork(x + 1), fork(x + 1)]
    else:
      tree = !7
  return tree`} />
<p>The program above will initialize a state (<code>x = 0</code>), and then, for as long as <code>x &lt; 3</code>, it will "fork" that state in two, creating a <code>Tree/Node</code>, and continuing
with <code>x + 1</code>. When <code>x &gt;= 3</code>, it will halt and return a <code>Tree/Leaf</code> with <code>7</code>.
When all is done, the result will be assigned to the <code>tree</code> variable:</p>
<CodeBlock code={`tree = fork(0)
tree = ![fork(1), fork(1)]
tree = ![![fork(2),fork(2)], ![fork(2),fork(2)]]
tree = ![![![fork(3),fork(3)], ![fork(3),fork(3)]], ![![fork(3),fork(3)], ![fork(3),fork(3)]]]
tree = ![![![!7, !7], ![!7, !7]], ![![!7, !7], ![!7, !7]]]`} />
<p>With some imagination, we can easily see that, by recursively unrolling a state
this way, we can generate any structure we&#39;d like. In fact, <code>bend</code> is so general
we can even use it to emulate a loop. For example, this Python program:</p>
<CodeBlock code={`sum = 0
idx = 0
while idx &lt; 10:
  sum = idx + sum
  idx = idx + 1`} />
<p>Could be emulated in Bend with a "sequential bend":</p>
<CodeBlock code={`bend idx = 0:
  when idx &lt; 10:
    sum = idx + fork(idx + 1)
  else:
    sum = 0`} />
<p>Of course, if you do it, Bend&#39;s devs will be very disappointed with you. Why?
Because everyone is here for one thing. Let&#39;s do it!</p>
<h2 id="parallel-hello-world">Parallel "Hello, World"</h2>
<p>So, after all this learning, we&#39;re now ready to answer the ultimate question:</p>
<p><strong>How do we write parallel algorithms in Bend?</strong></p>
<p>At this point, you might have the idea: by using <em>folds</em> and <em>bends</em>, right?
Well... actually not! You do not need to use these constructs at all to make it
happen. Anything that <em>can</em> be parallelized <em>will</em> be parallelized on Bend. To
be more precise, this:</p>
<pre><code>f(g(x))</code></pre>
<p>Can NOT be parallelized, because <code>f</code> <strong>depends</strong> on the result of <code>g</code>. But this:</p>
<pre><code>H(f(x), g(y))</code></pre>
<p>Can be parallelized, because <code>f(x)</code> and <code>g(y)</code> are <strong>independent</strong>. Traditional
loops, on the other hands, are inherently sequential. A loop like:</p>
<CodeBlock code={`sum = 0
for i in range(8):
  sum += i`} />
<p>Is actually just a similar way to write:</p>
<CodeBlock code={`sum = (0 + (1 + (2 + (3 + (4 + (5 + (6 + 7)))))))`} />
<p>Which is <em>really bad</em> for parallelism, because the only way to compute this is
by evaluating the expressions one after the other, in order:</p>
<CodeBlock code={`sum = (0 + (1 + (2 + (3 + (4 + (5 + (6 + 7)))))))
sum = (0 + (1 + (2 + (3 + (4 + (5 + 13))))))
sum = (0 + (1 + (2 + (3 + (4 + 18)))))
sum = (0 + (1 + (2 + (3 + 22))))
sum = (0 + (1 + (2 + 25)))
sum = (0 + (1 + 27))
sum = (0 + 28)
sum = 28`} />
<p>There is nothing Bend could do to save this program: sequentialism is an
inherent part of its logic. Now, if we had written, instead:</p>
<CodeBlock code={`sum = (((0 + 1) + (2 + 3)) + ((4 + 5) + (6 + 7)))`} />
<p>Then, we&#39;d have a much easier time evaluating that in parallel. Look at it:</p>
<CodeBlock code={`sum = (((0 + 1) + (2 + 3)) + ((4 + 5) + (6 + 7)))
sum = ((1 + 5) + (9 + 13))
sum = (6 + 22)
sum = 28`} />
<p>That&#39;s so much better that even the <em>line count</em> is shorter!</p>
<p>So, how do you write a parallel program in Bend?</p>
<p><strong>Just write algorithms that aren&#39;t helplessly sequential.</strong></p>
<p>That&#39;s all there is to it. As long as you write programs like that one, then
unlike the former one, they will run in parallel. And that&#39;s why <code>bend</code> and
<code>fold</code> are core features: they&#39;re, essentially, parallelizable loops. For
example, to add numbers in parallel, we can write:</p>
<CodeBlock code={`def main():
  bend d = 0, i = 0:
    when d &lt; 28:
      sum = fork(d+1, i*2+0) + fork(d+1, i*2+1)
    else:
      sum = i
  return sum`} />
<p>And that&#39;s the parallel "Hello, world"! Now, let&#39;s finally run it. But first,
let&#39;s measure its single-core performance. Also, remember that, for now, Bend
only supports 24-bit numbers (<code>u24</code>), thus, the results will always be in <code>mod 16777216</code>.</p>
</section>
  );

//   const QfBendContracts: React.FC = () => (
//     <section>
//       <h1>3. qfBend Smart Contracts</h1>
      
//       <p>qfBend is an extension of Bend specifically designed for writing smart contracts on the QuantumFusion platform. It combines Bend's powerful parallel computing capabilities with blockchain-specific constructs and safety features.</p>
  
//       <h2>3.1 Contract Definition</h2>
//       <p>To define a smart contract in qfBend, use the following syntax:</p>
//       <CodeBlock  code={`type MyContract = (Contract val)`} />
//       <p>This declaration tells the qfBend compiler that this file contains a smart contract. Only one contract can be defined per file.</p>
  
//       <h2>3.2 Function Types</h2>
//       <p>qfBend smart contracts support three types of functions:</p>
  
//       <h3>3.2.1 Public Functions</h3>
//       <p>Can mutate blockchain state and are externally accessible.</p>
//       <CodeBlock code={`def MyContract/Public/deposit(my_account, amount):
//       my_account.balance += amount
//       return 0`} />
  
//       <h3>3.2.2 View Functions</h3>
//       <p>Read-only functions that cannot mutate state.</p>
//       <CodeBlock code={`def MyContract/View/getBalance(address):
//       return address.balance`} />
  
//       <h3>3.2.3 Private Functions</h3>
//       <p>Internal use only, can modify state but with some limitations.</p>
//       <CodeBlock code={`def MyContract/Private/_mint(to_account, amount):
//       to_account.balance += amount
//       return 0`} />
  
//       <h2>3.3 State Management</h2>
  
//       <h3>3.3.1 Account Type</h3>
//       <p>qfBend provides a built-in `Account` type with the following fields:</p>
//       <ul>
//         <li>`address`: The account's address</li>
//         <li>`balance`: The account's native token balance</li>
//         <li>`data`: Byte array for account data (contract code, state, etc.)</li>
//         <li>`nonce`: Used for security checks</li>
//       </ul>
  
//       <h3>3.3.2 BigNumber Type</h3>
//       <p>For handling large numbers, especially for token amounts and balances:</p>
//       <CodeBlock code={`from_account.balance = BigNumber/add(from_account.balance, to_account.balance)
//   store_account.data = BigNumber/add(BigNumber/from_number(sum(10000)), "10000000000")`} />
  
//       <h3>3.3.3 Token Account Types</h3>
//       <ul>
//         <li>`Token/Holding`: Represents token holdings for an account</li>
//         <li>`Token/Mint`: Represents the token itself on the blockchain</li>
//       </ul>
  
//       <h2>3.4 Parallel Execution in Smart Contracts</h2>
//       <p>qfBend leverages Bend's parallel execution capabilities to optimize smart contract performance. However, it's important to note that while computations within a contract can be parallelized, the blockchain's state changes must ultimately be serialized to maintain consistency.</p>
  
//       <h3>3.4.1 Parallel Computations</h3>
//       <p>Use Bend's parallel constructs for computationally intensive operations:</p>
//       <CodeBlock code={`def MyContract/Public/complexOperation(data):
//     bend d = 0, i = 0:
//       when d < data.depth:
//         result = fork(d+1, i*2+0) + fork(d+1, i*2+1)
//       else:
//         result = heavyComputation(i)
//     return result`} />
  
//       <h3>3.4.2 State Updates</h3>
//       <p>State updates should be done carefully to ensure consistency:</p>
//       <CodeBlock code={`def MyContract/Public/batchUpdate(accounts, amounts):
//     fold accounts with index:
//       case List/Cons:
//         accounts.head.balance += amounts[index]
//         return accounts.tail(index + 1)
//       case List/Nil:
//         return 0`} />
  
//       <h2>3.5 Security Considerations</h2>
//       <ul>
//         <li>Public functions cannot execute other state-mutating functions to prevent unexpected state changes.</li>
//         <li>Use the `_account` suffix for parameters representing accounts to ensure proper type checking.</li>
//         <li>Implement proper error handling and state validation in all functions.</li>
//         <li>Be cautious with recursive calls, as they can lead to high gas costs or unexpected behavior.</li>
//       </ul>
  

//     <h2>3.6 Interacting with qfBend Contracts</h2>
//     <p>Interacting with qfBend contracts can be done through the QuantumFusion playground or by integrating with the QuantumFusion blockchain using web3 libraries. Here's how you can interact with a deployed contract:</p>

//     <h3>3.6.1 Using the Playground</h3>
//     <ol>
//       <li>Deploy your contract using the "Deploy" button in the playground.</li>
//       <li>Once deployed, you'll see a contract interaction panel.</li>
//       <li>Select the function you want to call from the dropdown.</li>
//       <li>Enter the required parameters.</li>
//       <li>Click "Execute" for state-changing functions or "Call" for view functions.</li>
//       <li>The result will be displayed in the console.</li>
//     </ol>


//     <h2>3.15 Conclusion</h2>
//     <p>qfBend represents a significant advancement in smart contract development, combining the power of parallel computing with the security and transparency of blockchain technology. By leveraging the unique features of Bend and the QuantumFusion platform, developers can create highly efficient, scalable, and innovative decentralized applications.</p>

//     <p>As you explore qfBend and develop smart contracts on the QuantumFusion platform, remember to prioritize security, optimize for gas efficiency, and take full advantage of the parallel computing capabilities offered by the language and runtime. The future of decentralized applications is here, and qfBend is at the forefront of this revolution.</p>
//   </section>
// );

const QfBendContracts = () => (
    <section>
      <h1>qfBend Smart Contracts</h1>
  
      <p>
        qfBend is an extension of Bend specifically designed for writing smart contracts on the QuantumFusion platform. 
        It combines Bend's powerful parallel computing capabilities with blockchain-specific constructs and safety features.
      </p>
  
      <h2>1. Contract Definition</h2>
      <p>To define a smart contract in qfBend, use the following syntax:</p>
      <CodeBlock code={`type MyContract = (Contract val)`} />
      <p>
        This declaration tells the qfBend compiler that this file contains a smart contract. 
        Only one contract can be defined per file. The 'val' parameter is a placeholder and can be any identifier.
      </p>
  
      <h2>2. Function Types</h2>
      <p>qfBend smart contracts support three types of functions:</p>
  
      <h3>2.1 Public Functions</h3>
      <p>Can mutate blockchain state and are externally accessible.</p>
      <CodeBlock code={`def MyContract/Public/deposit(my_account, amount):
  my_account.balance += amount
  return 0`} />
  
      <h3>2.2 View Functions</h3>
      <p>Read-only functions that cannot mutate state.</p>
      <CodeBlock code={`def MyContract/View/getBalance():
  return store_account.data`} />
      <p>or</p>
      <CodeBlock code={`def MyContract/Public/retrieve(store_account):
  return store_account.data`} />
  
      <h3>2.3 Private Functions</h3>
      <p>Internal use only, can modify state but with some limitations.</p>
      <CodeBlock code={`def MyContract/Private/_mint(to_account, amount):
  to_account.balance += amount
  return 0`} />
  
      <h2>3. State Management</h2>
  
      <h3>3.1 Account Type</h3>
      <p>qfBend provides a built-in `Account` type with the following fields:</p>
      <ul>
        <li><code>address</code>: The account's address</li>
        <li><code>balance</code>: The account's native token balance</li>
        <li><code>data</code>: Byte array for account data (contract code, state, etc.)</li>
        <li><code>nonce</code>: Used for security checks</li>
      </ul>
      <p>Example usage:</p>
      <CodeBlock code={`def MyContract/Public/transfer(from_account, to_account, amount):
  from_account.balance -= amount
  to_account.balance += amount
  return 0`} />
  
      <h3>3.2 BigNumber Type WorkInProgress</h3>
      <p>For handling large numbers, especially for token amounts and balances:</p>
      <CodeBlock code={`from_account.balance = BigNumber/add(from_account.balance, to_account.balance)
store_account.data = BigNumber/add(BigNumber/from_number(sum(10000)), "10000000000")`} />
  
      <h3>3.3 Token Account Types WorkInProgress</h3>
      <ul>
        <li><code>Token/Holding</code>: Represents token holdings for an account</li>
        <li><code>Token/Mint</code>: Represents the token itself on the blockchain</li>
      </ul>
  
      <h2>4. QRC20 Token Standard Example</h2>
      <p>Here's a basic implementation of a QRC20 token contract in qfBend:</p>
      <CodeBlock code={`type Qrc20 = (Contract val)
  
def Qrc20/View/name():
  return "Qrc20 Token"

def Qrc20/View/symbol():
  return "QF"

def Qrc20/View/decimals():
  return 18

def Qrc20/Public/transfer(to, amount):
  # Implementation omitted for brevity
  return 0

def Qrc20/View/balanceOf(address):
  # Implementation omitted for brevity
  return 0

def Qrc20/Private/_transfer(from, to, amount):
  # Implementation omitted for brevity
  return 0

def Qrc20/Private/_mint(from, amount):
  # Implementation omitted for brevity
  return 0`} />
  
      <h2>6. Security Considerations</h2>
      <ul>
        <li>Public functions cannot execute other state-mutating functions to prevent unexpected state changes.</li>
        <li>Use the `_account` suffix for parameters representing accounts to ensure proper type checking.</li>
        <li>Be cautious with recursive calls, as they can lead to unexpected behavior.</li>
      </ul>
  
      <h2>7. Limitations and Constraints</h2>
      <ul>
        <li>Public functions cannot call other functions that mutate blockchain state.</li>
        <li>Reserved types like Account, BigNumber, and Token cannot be redefined.</li>
        <li>Contracts must include the Contract type declaration to be recognized as smart contracts.</li>
      </ul>
  
      <h2>8. Onchain Storage Solution</h2>
      <p>
        qfBend introduces the ability to store calculation results in onchain storage and read them for subsequent calculations. 
        This is a significant innovation over the original Bend, which lacked permanent storage capabilities.
      </p>
      <p>Example of storing and retrieving data:</p>
      <CodeBlock code={`type StorageTest = (Contract val)

def StorageTest/Public/store(store_account, number):
  result = complexCalculation(number)
  store_account.data = result
  return store_account.data

def StorageTest/Public/retrieve(store_account):
    return store_account.data`} />
  
      <p>
        This feature allows smart contracts to maintain state between invocations, 
        enabling more complex and stateful applications on the QuantumFusion blockchain.
      </p>
  
      <h2>9. The main() Function</h2>
    <p>
      In qfBend, the `main()` function serves a special purpose for executing pure Bend code that does not interact with the blockchain. This is different from traditional Bend programs where `main()` is the entry point.
    </p>
    <p>
      For smart contracts:
      <ul>
        <li>The `main()` function is optional in qfBend smart contract files.</li>
        <li>If not present, it will be added "under the hood" by the compiler.</li>
        <li>It's used for pure computations that don't interact with blockchain state</li>
      </ul>
    </p>
    <p>
      Example of a `main()` function in a qfBend contract:
    </p>
    <CodeBlock code={`type MyContract = (Contract val)
#Contract functions here...
def main():
  #Pure Bend code for computations
  #This does not interact with blockchain state
  result = complexCalculation(100)
  return result`} />
    <p>
      The `main()` function in qfBend contracts allows developers to attach pure computational logic that can be executed using our explorer with "Run main" button.
    </p>

    </section>
  );

export default Docs;