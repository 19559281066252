import React, { useState } from 'react';
import { uploadData } from '../services/api';

const Benchmark: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!file) {
      setMessage('Please select a file to upload.');
      return;
    }

    setIsLoading(true);
    setMessage('');

    try {
      const response = await uploadData(file);
      setMessage(`Upload successful! Transaction hash: ${response.txHash}`);
    } catch (error) {
      setMessage(`Upload failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="upload-page">
      <h1>Benchmark</h1>
      <form onSubmit={handleSubmit} className="upload-form">
        <h2>Upload Bend program</h2>
        <div className="form-group">
            <label htmlFor="file-upload">Select file:</label>
            <input
            type="file"
            id="file-upload"
            onChange={handleFileChange}
            disabled={isLoading}
            />
        </div>
        <button type="submit" disabled={isLoading || !file}>
            {isLoading ? 'Uploading...' : 'Upload'}
        </button>
        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

export default Benchmark;