import { useEffect, useMemo, useState } from "react";
import CodeView from "./CodeView";
import { getContractDetails, runFunction } from "../services/api";
import CodeInput from "./CodeInput";
import CodeRunner from "./CodeRunner";
import LoadingSpinner from "./LoadingSpinner";

const ContractDetails = ({ accountData }: any) => {

    const [details, setDetails] = useState<any>({});
    const [mode, setMode] = useState(0);

    useEffect(() => {
        getContractDetails(accountData.address).then((data) => {
            setDetails(data)
        })
    }, [accountData.data])

    return (
        <div className="m-2 mb-20 p-2 rounded-lg bg-white/5 border-gray-200">

            <div className='flex gap-2 p-2'>
                <button className={"py-1 px-3 text-sm rounded-lg " + (!mode ? 'text-white bg-[#0784c3] hover:bg-[#0784c3cb]' : 'text-primary-500  hover:bg-gray-400 bg-gray-300')} onClick={() => setMode(0)}> Code </button>
                <button className={"py-1 px-2 text-sm rounded-lg " + (mode === 1 ? 'text-white bg-[#0784c3] hover:bg-[#0784c3cb]' : 'text-primary-500  hover:bg-gray-400 bg-gray-300')} onClick={() => setMode(1)}> Read Contract </button>
                <button className={"py-1 px-2 text-sm rounded-lg " + (mode === 2 ? 'text-white bg-[#0784c3] hover:bg-[#0784c3cb]' : 'text-primary-500  hover:bg-gray-400 bg-gray-300')} onClick={() => setMode(2)}> Write Contract </button>
            </div>

            {mode === 0 && <CodeSection accountData={accountData} details={details} />}
            {mode === 1 && <ReadSection details={details} accountAddress={accountData?.address ?? ""}/>}
            {mode === 2 && <WriteSection details={details} accountAddress={accountData?.address ?? ""} />}

        </div>
    )
}

const CodeSection = ({ accountData, details }: any) => {
    return (
        <div className="flex flex-col p-2 gap-2">
            <div className="h-[500px]">
                <p className="text-white/85">Bend</p>
                <CodeRunner code={accountData.data} />
                <div className="h-[420px] overflow-scroll">
                    <CodeInput content={accountData.data} readonly={true} />
                </div>
            </div>
            <div className="h-[200px]">
                <p>HVM</p>
                <div className="h-[180px] overflow-scroll">
                    <CodeView content={details?.hvm ?? ""} />
                </div>
            </div>
            <div className="h-[200px]">
                <p>Abi</p>
                <div className="h-[180px] overflow-scroll">
                    <CodeView content={details?.abi ?? ""} />
                </div>
            </div>
        </div>
    )
}

const ReadSection = ({ details, accountAddress }: any) => {
    const functions = useMemo(() => {
        let result: any = [];
        let abi = JSON.parse(details?.abi ?? "{}");
        if (abi?.functions) {
            for (let func of abi.functions) {
                if (func.stateMutability === 'view') {
                    result.push(func);
                }
            }
        }
        return result;
    }, [details])

    return (
        <div className="p-2">
            <Web3Connect />
            {
                details?.abi ?
                    functions.map((f: any, idx: number) => (
                        <div key={idx}>
                            <FuncSection f={f} params={f?.params ?? []} idx={idx + 1} isRead={true} accountAddress={accountAddress}/>
                        </div>
                    )) :
                    <LoadingSpinner />
            }
        </div>
    )
}

const WriteSection = ({ details, accountAddress }: any) => {
    const functions = useMemo(() => {
        let result: any = [];
        let abi = JSON.parse(details?.abi ?? "{}");
        if (abi?.functions) {
            for (let func of abi.functions) {
                if (func.stateMutability !== 'view') {
                    result.push(func);
                }
            }
        }
        return result;
    }, [details])

    return (
        <div className="p-2">
            <Web3Connect />
            {
                functions.map((f: any, idx: number) => (
                    <div key={idx}>
                        <FuncSection f={f} params={f?.params ?? []} idx={idx + 1} isRead={false} accountAddress={accountAddress}/>
                    </div>
                ))
            }
        </div>
    )
}

const Web3Connect = () => {
    return (
        <div className="mb-3">
            <button disabled className={"p-1 px-2 flex gap-1 text-sm rounded-lg text-primary-500  hover:bg-gray-400   border-gray-100"}>
                <svg className="w-4 h-4 text-red-600 my-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1" clipRule="evenodd" />
                </svg>
                <span>Connect to Web3</span>
            </button>
        </div>
    )
}

const FuncSection = ({ f, idx, params, isRead, accountAddress }: { f: any, idx: number, params: any[], isRead: boolean, accountAddress: string }) => {
    return (
        <div className="mt-2">
            <FuncAccordion header={idx + ". " + (f?.name ?? "")} body={<FuncBody f={f} isRead={isRead} accountAddress={accountAddress}/>} />
        </div>
    )
}

const FuncBody = ({ f, isRead, accountAddress }: { f: any, isRead: boolean, accountAddress: string }) => {
    const params: any = f?.params ?? [];
    const value: any = f?.value ?? 0;

    const [paramValues, setParamValues] = useState<any[]>([]);
    const [runResult, setRunResult] = useState<string | number>("");
    const [runHash, setRunHash] = useState<string>("");
    const [isRunning, setIsRunning] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorString, setErrorString] = useState("Please input the parameter properly.");

    const updateParamValues = (e: any, idx: number) => {
        let v: any[] = paramValues;
        v[idx] = e.target.value;
        setParamValues(v);
    }

    const runFunc = () => {
        console.log("paramValues =", paramValues);
        let inputCount = f?.inputs ?? 0;

        let isLegit = paramValues.length >= inputCount && paramValues.every((v) => v && v !== "") && !paramValues.includes(undefined)
        if (!isLegit) {
            setErrorString("Please input the parameter properly.");
            setHasError(true);
            return;
        }
        setHasError(false);
        const funcName = f?.name ?? "";
        
        setIsRunning(true);
        runFunction(accountAddress, funcName, paramValues).then((res) => {
            if (res?.error) {
                setErrorString(res?.error);
                setHasError(true);
                setIsRunning(false)
            } else {   
                setRunResult(res?.result?.value ?? "");
                setRunHash(res?.result?.executeHash ?? "");
                setIsRunning(false)   
            }
        }).catch(() => {
            setErrorString("Error occured while executing the function.");
            setHasError(true);
            setIsRunning(false)
        });
    }

    return (
        <div className="p-2">
            {
                params.length ?
                    <div>
                        {params.map((p: any, idx: number) => (
                            <div key={idx} className="flex flex-col mt-2">
                                <span className="p-1 text-sm">{p}</span>
                                <input type="text" placeholder={p} className="p-1 px-2 border-2 border-primary-200 rounded-lg bg-primary-400" onChange={(e) => updateParamValues(e, idx)}></input>
                            </div>
                        ))}
                        <div className="flex gap-2">
                            <button className="text-sm p-1 m-1 mt-2 rounded-md inline w-[60px] text-primary-500  hover:bg-gray-400 bg-gray-300" onClick={runFunc}>{isRead ? "Query" : "Write"}</button>
                            { isRunning && <div className="my-auto"> <LoadingSpinner/> </div>}
                            { !isRunning && runResult !== "" && <span className="my-auto pt-1"> Result: {runResult} </span> }
                        </div>
                        {hasError && <p className="mt-2 px-1 text-sm text-[red] ">{errorString}</p>}
                    </div> : <></>
            }
            {
                !params.length &&
                <div>
                    {value}
                </div>
            }
        </div>
    )
}

const FuncAccordion = ({ header, body }: any) => {

    const [opened, setOpened] = useState(false);

    return (
        <div>
            <h2 id="accordion-collapse-heading-1" className='mt-1 flex'>
                <button type="button" className="flex items-center justify-between w-full p-2 px-3 rounded-lg font-medium rtl:text-right bg-primary-500 hover:bg-primary-400 text-white/85 border border-b-0 border-primary-200 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1"
                    onClick={() => setOpened(!opened)}
                >
                    <span>{header}</span>
                    <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                    </svg>
                </button>
            </h2>
            <div id="accordion-collapse-body-1" className={`my-2 overflow-hidden justify-start transition-max-height duration-300 ease-in-out ${opened ? "max-h-96" : "max-h-0"}`} aria-labelledby="accordion-collapse-heading-1">
                <div className="border border-primary-100 rounded-lg text-white/85">
                    {body}
                </div>
            </div>
        </div>
    )
}
export default ContractDetails;