import { useState } from "react";
import { searchAccount } from "../services/api";
import { useNavigate } from "react-router-dom";

const SearchBox = () => {
    const navigate = useNavigate();

    const [searchText, setSearchText] = useState("");
    const onClickSearch = (e: any) => {
        e.preventDefault();
        searchAccount(searchText).then((res) => {
            if (res.type === 'transaction') {
                navigate(`/txn/${res.content}`);
            } else {
                navigate(`/accounts/${res.content}`);
            }
        }).catch(() => {
            navigate(`/404`);
        })
    }

    return (
        <form className="max-w-lg block w-full" onSubmit={(e) => onClickSearch(e)} method="">
            <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
            <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </div>
                <input type="search" id="default-search" value={searchText} onChange={(e) => setSearchText(e.target.value)} className="block w-full sm:w-[420px] p-4 ps-10 text-sm text-white/85 border border-primary-200 rounded-lg bg-primary-400 focus:ring-primary-50 focus:border-primary-50" placeholder="Search Accounts and Transactions" required />
                <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-accent-500 hover:bg-accent-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
            </div>
        </form>
    )
}

export default SearchBox;