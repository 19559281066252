import { useState } from "react";
import { runContract } from "../services/api";

const CodeRunner = ({ code } : { code?: string }) => {
    const [result, setResult] = useState("Result:\n");
    const [isRunning, setIsRunning] = useState(false);

    const onClickRun = () => {
        setIsRunning(true);
        runContract(code ?? "").then((res) => {
            setResult(res.result);
            setIsRunning(false);
        })
    }

    return (
        <div className="flex flex-col sm:flex-row">
            <div className="flex">
            <button className="flex my-2 p-2 bg-accent-500 rounded-lg h-10" onClick={onClickRun}>
                <span className="px-2">Run main</span>
                <svg width="25" height="25" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="48" stroke="white" strokeWidth="2" fill="none" />
                    <polygon points="40,30 70,50 40,70" fill="white" />
                </svg>
            </button>
                {isRunning && (
                    <div className="m-3 my-auto border-t-transparent border-solid animate-spin rounded-full border-blue-500 border-4 h-4 w-4"></div>
                )}
        </div>
            {!isRunning && (
                <textarea 
                    readOnly 
                    value={result} 
                    className="bg-primary-400 mb-2 md:m-3 mt-auto w-full sm:flex-1 px-1 text-white/85" 
                />
            )}
        </div>
    )
}

export default CodeRunner;