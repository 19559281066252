import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTransactionDetails } from "../services/api";
import CodeInput from "../components/CodeInput";
import CodeRunner from "../components/CodeRunner";
import SearchBox from "../components/AccountSearch";
import ClipboardButton from "../components/ClipboardButton";
import ExplorerHeader from "../components/ExplorerHeader";

const TxnDetails = () => {
    let { id } = useParams();

    const [txData, setTxData] = useState<any>({});
    useEffect(() => {
        if (id) {
            getTransactionDetails(id).then((data) => {
                setTxData(data[0]);
            })
        }
    }, [id])
    return (
        <div className='mx-auto max-w-7xl'>
            <ExplorerHeader/>
            <div className="p-4">
                <span className="text-white text-lg font-bold">Transaction Details</span>
                <hr className="py-3" />
                <div>
                    <div className="flex flex-col sm:flex-row py-2">
                        <div className="w-full sm:w-[20%] text-gray-400">Transaction Hash: </div>
                        <div className="flex gap-2 text-white/85 break-all">
                            <span>{id}</span>
                            <ClipboardButton withoutCopyText={true} content={id} />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row py-2">
                        <div className="w-full sm:w-[20%] text-gray-400">Status: </div>
                        <div className="p-1 px-2 bg-green-200 rounded-lg text-green-600 inline-block">Success</div>
                    </div>
                    <div className="flex flex-col sm:flex-row py-2">
                        <div className="w-full sm:w-[20%] text-gray-400">Timestamp: </div>
                        <div className="text-white/85">{txData?.timestamp ?? 0}</div>
                    </div>
                    <div className="flex flex-col sm:flex-row py-2">
                        <div className="w-full sm:w-[20%] text-gray-400">From: </div>
                        <div className="flex gap-2 break-all">
                            <a href={`/accounts/${txData.from}`} className="underline text-white/85">{txData.from}</a>
                            <ClipboardButton withoutCopyText={true} content={txData.from} />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row py-2">
                        <div className="w-full sm:w-[20%] text-gray-400">To: </div>
                        <div className="flex gap-2 break-all">
                            <a href={`/accounts/${txData.to}`} className="underline text-white/85">{txData.to}</a>
                            <ClipboardButton withoutCopyText={true} content={txData.to} />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row py-2">
                        <div className="w-full sm:w-[20%] text-gray-400">ZK Proof: </div>
                        <div className="w-full sm:w-[80%]">
                            <textarea className="p-2 bg-primary-400 text-white/85 w-full" readOnly rows={7} value={txData?.zkproof ?? ""}/>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row py-2">
                        <div className="w-full sm:w-[20%] text-gray-400">ZK Verification Result: </div>
                        <div>
                            <span className="p-2 px-3 bg-gray-200 rounded-lg font-bold">{txData?.zkverification ? "True" : "False"}</span>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row py-2">
                        <div className="w-full sm:w-[20%] text-gray-400">Details: </div>
                        {
                            txData.type === 'Deployment' ?
                                <div className="w-full sm:w-[80%]">
                                    <span className="py-3 font-bold text-white/85">Contract Deployment Transaction</span>
                                    <CodeRunner code={txData.details} />
                                    <div className="overflow-x-auto">
                                        <CodeInput content={txData.details} readonly={true} />
                                    </div>
                                </div>
                                : <div className="break-all text-white/85">{txData.details}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TxnDetails;