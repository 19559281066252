import React from 'react';

interface Contract {
  id: string;
  author: string;
  date: string;
  txHash: string;
  gasFee: string;
}

const BPList: React.FC = () => {
  const contracts: Contract[] = [
    { id: '1', author: 'Alice', date: '2024-08-16', txHash: '0x123...abc', gasFee: '0.001 QF' },
    { id: '2', author: 'Bob', date: '2024-08-15', txHash: '0x456...def', gasFee: '0.002 QF' },
  ];

  return (
    <div className="contract-list">
      <h2>Uploaded Bend Smart Contracts</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Author</th>
            <th>Date</th>
            <th>TX Hash</th>
            <th>Gas Fee</th>
          </tr>
        </thead>
        <tbody>
          {contracts.map((contract) => (
            <tr key={contract.id}>
              <td>{contract.id}</td>
              <td>{contract.author}</td>
              <td>{contract.date}</td>
              <td>{contract.txHash}</td>
              <td>{contract.gasFee}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BPList;