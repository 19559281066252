import React from 'react';

interface WarningPopupProps {
  onClose: () => void;
}

const WarningPopup: React.FC<WarningPopupProps> = ({ onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-primary-400 text-white p-6 rounded-lg max-w-md">
      <h2 className="text-xl font-bold mb-4">⚠️ Development Environment</h2>
      <div className="mb-4">
        This playground is under active development:
        <ul className="list-disc list-inside">
          <li>Expect instability and data loss</li>
          <li>State may be wiped without notice</li>
          <li>If deployment fails, try refreshing</li>
        </ul>
      </div>
      <p className="mb-4">Use at your own risk. Not for production.</p>
      <button
        onClick={onClose}
        className="bg-accent-500 hover:bg-accent-600 text-white px-4 py-2 rounded transition-colors"
      >
        I Understand
      </button>
    </div>
  </div>
);

export default WarningPopup;