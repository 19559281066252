import { useEffect, useMemo, useRef, useState } from "react";
import { DeployResult } from "../pages/Ide";
import { useSocket } from "../contexts/SocketContext";
import * as G from "../utils/G";

type DeployStatus = {
  sequencerPassed: boolean;
  zkKeyReturned: boolean;
  zkResult: boolean;
};
const Console = ({
  isCompiling,
  isDeploying,
  compileError,
  compileWarning,
  deployResult,
  deployId,
}: {
  isCompiling: number;
  isDeploying: number;
  deployId: number;
  compileError: string;
  compileWarning: string;
  deployResult: DeployResult;
}) => {
  const { socket, isConnected } = useSocket();

  useEffect(() => {
    if (!socket) return;

    const onMessage = (msg: any) => {
      let data: any = {};
      try {
        data = JSON.parse(msg).message;
      } catch (e) {
        console.error(e);
      }

      console.log("data =", data);
      if (data.type === 0) {
        setSeqPassed(true);
      } else if (data.type === 1) {
        setZkKey(data?.keys?.pk ?? "");
        setZkKeyReturned(true);
      } else if (data.type === 2) {
        setZkProofText(data?.zkproof ?? "");
        setZkResultRet(true);
      }
    };

    socket.on("message", onMessage);

    return () => {
      socket.off("message", onMessage);
    };
  }, [socket]);

  const [consoleText, setConsoleText] = useState("Console:");
  const [zkProofText, setZkProofText] = useState("");
  const [zkKey, setZkKey] = useState("");
  const [logLevel, setLogLevel] = useState(0);

  const [seqPassed, setSeqPassed] = useState(false);
  const [zkKeyReturned, setZkKeyReturned] = useState(false);
  const [zkResultRet, setZkResultRet] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);
  const preRef = useRef<any>(null);

  useEffect(() => {
    if (!isConnected) {
    }
  }, [isDeploying]);

  useEffect(() => {
    console.log("deployStatus =", seqPassed, zkKeyReturned, zkResultRet);
  }, [seqPassed, zkKeyReturned, zkResultRet]);

  useEffect(() => {
    let newConsole = consoleText;
    if (isCompiling === 1) {
      newConsole += "Compiling ..." + "\n";
    } else if (isCompiling === 2) {
      let result = "";
      if (compileWarning) {
        result += "\n" + compileWarning.replace(/\u001b\[[0-9;]*m/g, "");
      }
      result += compileError ? "Compile Failed." : "Compile Succeeded." + "\n";
      newConsole += result;
    }
    setConsoleText(newConsole);
  }, [isCompiling]);

  useEffect(() => {
    let newConsole = consoleText;
    let newLogLevel = logLevel;
    if (isDeploying === 1 && newLogLevel === 0) {
      newConsole += "Deploying ..." + "\n";
      newLogLevel = 1;
    }

    if (seqPassed && newLogLevel === 1) {
      newConsole += "\nSending result to sequencer...";
      newConsole += "\nSequencer: Successfully constructed a batch.\n";
      newConsole += "Generating ZK Proving Key ..." + "\n";
      newLogLevel = 2;
    }

    if (zkKeyReturned && newLogLevel === 2) {
      newConsole += `\n<div class='flex items-start'>ZK Prover: PK = <textarea rows='6' class='p-2 w-[70%] text-secondary bg-[#080808] border-l border-[#222222]'>${zkKey}</textarea></div>`;
      newConsole += "\nZkVerifier: Verifying...\n";
      newLogLevel = 3;
    }

    if (zkResultRet && newLogLevel === 3) {
      newConsole += `\n<div class='flex items-start'>ZK Verifier: Proof = <textarea rows='6' class='p-2 w-[70%] text-secondary bg-[#080808] border-l border-[#222222]'>${zkProofText}</textarea></div>`;
      newLogLevel = 4;
    }

    if (isDeploying === 2 && newLogLevel === 4) {
      if (deployResult.error) {
        newConsole += "Deploy Failed." + "\n" + deployResult.error + "\n";
      } else {
        if (!deployResult.isDeployable) newConsole += "The Bend code is not in the form of smart contract. \nThis will be deployed for test." + "\n";
        newConsole += "\nDeployed successfully to account: ";
        newConsole +=
          `<a target="_blank" href=/accounts/${deployResult.address} style="text-decoration: underline"}>${deployResult.address}</a>` + `<br/>`;
        newConsole +=
          `Transaction Hash: <a target="_blank" href=/txn/${deployResult.txHash} style="text-decoration: underline"}>${deployResult.txHash}</a>` +
          `<br/>`;
      }

      setZkProofText("");
      setZkKey("");

      if (!seqPassed || !zkKeyReturned || !zkResultRet) G.log("------------- prev state: ", seqPassed, zkKeyReturned, zkResultRet);
      setSeqPassed(false);
      setZkKeyReturned(false);
      setZkResultRet(false);

      newLogLevel = 0;
    }

    setLogLevel(newLogLevel);
    setConsoleText(newConsole);
  }, [isDeploying, seqPassed, zkKeyReturned, zkResultRet, deployResult]);

  useEffect(() => {
    if (preRef?.current) {
      preRef.current.innerHTML = consoleText;
    }
    if (divRef?.current) {
      let ele = divRef?.current;
      ele.scrollTop = divRef?.current.scrollHeight;
    }
  }, [consoleText]);

  return (
    <div
      className="lg:max-h-[calc(100vh-273px)] h-full w-full overflow-scroll font-mono text-secondary bg-[#080808] border-l border-[#222222]"
      ref={divRef}
    >
      <pre
        className="block border-0 p-2.5 text-lg h-full text-gray-600 focus:ring-blue-500 dark:text-white dark:focus:ring-blue-500"
        ref={preRef}
      ></pre>
    </div>
  );
};

export default Console;