import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Benchmark from "./pages/Benchmark";
import Tools from "./pages/Tools";
import More from "./pages/More";
import IDE from "./pages/Ide";
import Explorer from "./pages/Explorer";
import "./styles.css";
import TxnDetails from "./pages/TxnDetails";
import AccountDetails from "./pages/AccountDetails";
import NotFound from "./pages/404";
import { SocketProvider } from "./contexts/SocketContext";
import Docs from "./pages/Docs";
import WarningPopup from "./components/WarningPopup";

const App: React.FC = () => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const hasSeenWarning = localStorage.getItem("hasSeenWarning");
    if (!hasSeenWarning) {
      setShowWarning(true);
    }
  }, []);

  const handleCloseWarning = () => {
    setShowWarning(false);
    localStorage.setItem("hasSeenWarning", "true");
  };

  return (
    <SocketProvider>
      <Router>
        <div className="app flex flex-col h-screen">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<IDE />} />
              <Route path="/explorer" element={<Explorer />} />
              <Route path="/accounts/:id" element={<AccountDetails />} />
              <Route path="/txn/:id" element={<TxnDetails />} />
              <Route path="/home" element={<Home />} />
              <Route path="/benchmark" element={<Benchmark />} />
              <Route path="/tools/" element={<Tools />} />
              <Route path="/more/*" element={<More />} />
              <Route path="/404/" element={<NotFound />} />
              <Route path="/docs/" element={<Docs />} />
            </Routes>
          </main>
          {showWarning && <WarningPopup onClose={handleCloseWarning} />}
          {/* <Footer /> */}
        </div>
      </Router>
    </SocketProvider>
  );
};

export default App;