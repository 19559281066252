import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { EditorView } from '@codemirror/view';
import { tags as t } from '@lezer/highlight';
import { createTheme } from '@uiw/codemirror-themes';

const myTheme = createTheme({
  theme: 'dark',
  settings: {
    background: '#151515',
    foreground: '#ffffff',
    caret: '#dc0839',
    selection: '#dc083930',
    selectionMatch: '#dc083960',
    lineHighlight: '#dc083920',
  },
  styles: [
    { tag: t.comment, color: '#6a9955' },
    { tag: t.variableName, color: '#9cdcfe' },
    { tag: t.string, color: '#ce9178' },
    { tag: t.number, color: '#b5cea8' },
    { tag: t.keyword, color: '#ff1a4f' },
    { tag: t.operator, color: '#d4d4d4' },
    { tag: t.punctuation, color: '#d4d4d4' },
  ],
});

const CodeView = ({ content }: any) => {
    let jsonContent;
    try {
        jsonContent = JSON.stringify(JSON.parse(content), null, 2);
    } catch (e) {
        jsonContent = (typeof content === 'string') ? content : "";
    }

    return (
        <CodeMirror
            value={jsonContent}
            height="auto"
            theme={myTheme}
            extensions={[json(), EditorView.lineWrapping]}
            editable={false}
            className="max-h-[calc(40vh-160px)] block w-full text-lg text-secondary bg-primary border-none overflow-auto"
        />
    );
};
export default CodeView;