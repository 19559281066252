import axios from "axios";

export const isTsNode = process.env.TS_NODE_DEV !== undefined;
export const DEBUG = process.env.NODE_ENV !== "production";

export const API_URL = DEBUG ? process.env.REACT_APP_API_URL_DEV! : process.env.REACT_APP_API_URL!;
export const SIO_URL = DEBUG ? process.env.REACT_APP_SIO_URL_DEV! : process.env.REACT_APP_SIO_URL!;

export const XTOKEN = isTsNode ? process.env.XTOKEN_DEV : process.env.XTOKEN!;

export const LOG_MODE = "dev";
export function log(...args: any) {
  if (LOG_MODE === "dev") console.log(...args);
}
export function sleep(ms: number) {
  return new Promise((r) => setTimeout(r, ms));
}

export function getTimeStr(time: number = 0) {
  const date = time
    ? new Date(time * 1000 + 9 * 3600000)
    : new Date(Date.now() + 9 * 3600000);
  return date.toISOString().replace(/[T]/g, " ").substring(0, 19);
}

export function f(num: number) {
  if (num === undefined || num === null) return "";
  let rlt = "";
  if (num >= 1e9) {
    rlt = (num / 1e9).toFixed(1) + "B";
  } else if (num >= 1e6) {
    rlt = (num / 1e6).toFixed(1) + "M";
  } else if (num >= 1e3) {
    rlt = (num / 1e3).toFixed(1) + "K";
  } else if (num >= 1) {
    if (Number.isInteger(num)) rlt = num.toString();
    else rlt = num.toFixed(1);
  } else if (num === 0) {
    rlt = "0";
  } else if (num < 0.00001) {
    num = Number(num.toPrecision(4));
    rlt = num.toExponential();
    rlt = Number(rlt).toString();
  } else {
    rlt = num.toPrecision(4);
    rlt = Number(rlt).toString();
  }
  return rlt;
}

export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getUTCDaysSinceEpoch(date: Date) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const timestamp = date.getTime();
  return Math.floor(timestamp / millisecondsPerDay);
}

let _prev: string | null = null;
const _tasks: Map<string, number> = new Map();
export function _b(
  task: string | null | undefined = null,
  show: boolean = true
) {
  if (!DEBUG) return;
  let rlt = 0;
  if (!task && _prev) {
    task = _prev;
    _prev = null;
  }
  if (task) {
    const prev = _tasks.get(task);
    if (prev) {
      rlt = Date.now() - prev;
      if (show) log(`__B__        ${task}  =>  ${rlt} ms`);
      _tasks.delete(task);
    } else {
      _tasks.set(task, Date.now());
      _prev = task;
    }
  }
  return rlt;
}

export const fetchAPI = async (
  url: string,
  method: "GET" | "POST",
  data: Record<string, any> = {}
): Promise<any | null> => {
  return new Promise((resolve) => {
    if (method === "POST") {
      axios
        .post(url, data)
        .then((response) => {
          let json = response.data;
          resolve(json);
        })
        .catch((error) => {
          resolve(null);
        });
    } else {
      axios
        .get(url)
        .then((response) => {
          let json = response.data;
          resolve(json);
        })
        .catch((error) => {
          resolve(null);
        });
    }
  });
};

export const toLamports = (num: number) => {
  if (num <= 0) return BigInt(0);
  let numerator = Math.floor(num);
  let denominator = (num - numerator) * 10 ** 9;
  return BigInt(numerator) * BigInt(10 ** 9) + BigInt(denominator);
};

export const toSol = (num: bigint) => {
  let lamports = num;
  return (
    Number(lamports / BigInt(10 ** 9)) +
    Number(lamports % BigInt(10 ** 9)) / 10 ** 9
  );
};

export function generateRandomString(length: number): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}