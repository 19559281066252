import React, { useEffect, useMemo, useState } from 'react';
import { generateAvatarURL } from "@cfx-kit/wallet-avatar";
import ClipboardButton from '../components/ClipboardButton';
import TransactionList from '../components/TransactionList';
import ContractDetails from '../components/ContractDeatils';
import { getAccountDetails, getTransactions } from '../services/api';
import { useParams } from 'react-router-dom';
import SearchBox from '../components/AccountSearch';
import ExplorerHeader from '../components/ExplorerHeader';

const AccountDetails: React.FC = () => {
    const [isDisplayTransaction, setDisplayTransaction] = useState(true);

    const [transactionList, setTransactionList] = useState<any>([]);
    const [accountData, setAccountData] = useState<any>([]);

    let { id: accountAddress } = useParams();

    const randomAvatar = useMemo(() => {
        setDisplayTransaction(true);
        return generateAvatarURL(accountAddress ?? "")
    }, [accountAddress])

    useEffect(() => {
        if (accountAddress) {
            getAccountDetails(accountAddress).then((data) => {
                setTransactionList(data?.transactions ?? [])
                setAccountData(data?.account ?? {});
            });
        }
    }, [accountAddress]);

    return (
        <div className='mx-auto max-w-7xl'>
            <div className="home">
                <ExplorerHeader/>

<div className='flex flex-col sm:flex-row items-start sm:items-center gap-2 p-2'>
    <div className='flex items-center gap-2'>
        <img src={randomAvatar} alt="avatar" className="inline-block relative object-cover object-center !rounded-full w-8 h-8 cursor-pointer" />
                    <span className='font-bold text-white/85'>Contract</span>
                    <span className='text-white/65'>at</span>
                </div>
    <div className='flex flex-wrap items-center gap-1'>
        <span className="font-bold text-white/85 break-all">{accountAddress} <ClipboardButton withoutCopyText={true} content={accountAddress}/></span>
        
    </div>
</div>

<div className='flex flex-col sm:flex-row gap-2 p-2 w-full'>
    <div className='flex flex-col w-full sm:w-1/3 rounded-md border border-white/25 p-3 bg-white/10 text-white/85'>
                        <span className='font-extrabold'>Overview</span>
        <div className='flex justify-between sm:flex-col'>
                        <span>QF Balance</span>
            <span className='sm:mt-1'>{accountData.balance}</span>
                    </div>
                    </div>
    <div className='flex flex-col w-full sm:w-1/3 rounded-md border border-white/25 p-3 bg-white/10 text-white/85'>
        <span className='font-extrabold'>More Info</span>
        <div className='flex justify-between sm:flex-col'>
            <span>Contract Deployed Time</span>
<span className='sm:mt-1'>{new Date(accountData.createdAt).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</span>
                    </div>
                </div>
    <div className='flex flex-col w-full sm:w-1/3 rounded-md border border-white/25 p-3 bg-white/10 text-white/85'>
        <span className='font-extrabold'>Account Data</span>
        <span className='truncate'>
            {accountData?.type === 0 ? "Smart Contract Code" : (accountData?.data ?? "")}
        </span>
    </div>
</div>

                <div className='flex gap-2 p-2'>
                    <button 
                        className={`p-2 px-3 rounded-xl ${
                            isDisplayTransaction 
                            ? 'bg-accent-500 font-bold text-white' 
                            : 'bg-accent-100 text-primary-500 hover:bg-accent-200 hover:text-white'
                        }`} 
                        onClick={() => setDisplayTransaction(true)}
                    > 
                        Transactions 
                    </button>
                    {accountData?.type === 0 && (
                        <button 
                            className={`p-2 px-3 rounded-xl ${
                                !isDisplayTransaction 
                                ? 'bg-accent-500 font-bold text-white' 
                                : 'bg-accent-100 text-primary-500 hover:bg-accent-200 hover:text-white'
                            }`} 
                            onClick={() => setDisplayTransaction(false)}
                        > 
                            Contract 
                        </button>
                    )}
                </div>

                <div>
                    {
                        isDisplayTransaction ? <TransactionList transactionList={transactionList} /> : <ContractDetails accountData={accountData} />
                    }

                </div>
            </div>
        </div>
    );
};

export default AccountDetails;