import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const [showToolsDropdown, setShowToolsDropdown] = useState(false);
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="relative">
      <div className="mx-auto flex justify-between items-center">
        <Link to="/">
          <img src="/logo.png" alt="QuantumFusion Logo" className="h-12 w-auto" />
        </Link>

        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="md:hidden z-20"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>

        <nav className={`${isMenuOpen ? 'flex' : 'hidden'} md:flex absolute z-50 md:relative top-full left-0 right-0 bg-primary-500 md:bg-transparent flex-col md:flex-row w-full md:w-auto p-4 md:p-0 shadow-lg md:shadow-none`}>
          <ul className="md:flex md:space-x-4 md:space-y-0">
            <li><Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link></li>
            <li><Link to="/explorer" onClick={() => setIsMenuOpen(false)}>Explorer</Link></li>
            {/* <li><Link to="/benchmark" onClick={() => setIsMenuOpen(false)}>Benchmark</Link></li> */}
            <li><Link to="/docs" onClick={() => setIsMenuOpen(false)}>Docs</Link></li>
            
            <li><Link to="/tools" onClick={() => setIsMenuOpen(false)}>Tools</Link></li>
            {/* <li 
              onMouseEnter={() => setShowToolsDropdown(true)}
              onMouseLeave={() => setShowToolsDropdown(false)}
            >
              <span className='cursor-pointer'>Tools</span>
              {showToolsDropdown && (
                <ul className="dropdown z-10">
                  <li><Link to="/tools/hvm-savvy">HVM Savvy</Link></li>
                  <li><Link to="/tools/bend-playground">Bend Playground</Link></li>
                  <li><Link to="/tools/converter">Converter</Link></li>
                </ul>
              )}
            </li> */}
            <li 
              onClick={() => setShowMoreDropdown(!showMoreDropdown)}
              className="relative"
            >
              <span className='cursor-pointer'>More</span>
              {showMoreDropdown && (
                <ul className="md:absolute md:top-full md:left-0 bg-white md:shadow-lg p-2 space-y-2">
                  {/*<li><Link to="/more/technical-guidance" onClick={() => setIsMenuOpen(false)}>Technical Guidance</Link></li>
                  <li><Link to="/more/about-us" onClick={() => setIsMenuOpen(false)}>About Us</Link></li>
                  <li><Link to="/more/partnerships" onClick={() => setIsMenuOpen(false)}>Partnerships</Link></li>
                  <li><Link to="/more/community" onClick={() => setIsMenuOpen(false)}>Community</Link></li> */}
                </ul>
              )}
            </li>
          </ul>
        </nav>

      </div>
    </header>
  );
};

export default Header;
