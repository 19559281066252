import axios from "axios";
import { io, Socket } from "socket.io-client";
import * as G from "../utils/G";

export const uploadData = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(`${G.API_URL}/upload`, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Upload failed");
  }

  return response.json();
};

export const uploadCode = async (codeContent: string, callback: any) => {
  axios
    .post(`${G.API_URL}/ide/compile`, {
      code: codeContent,
    })
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      G.log(error);
    });
};

export const deployCode = async (codeContent: string, deployId: number, sid: string) => {
  let response = await axios.post(`${G.API_URL}/ide/deploy`, {
    code: codeContent,
    deployId,
    sid,
  });
  return response.data;
};

export const getTransactions = async (fromAddress: string) => {
  let transactions: any = [];
  try {
    let res = await axios.get(`${G.API_URL}/explorer/transactions?fromAddress=${fromAddress}`);
    transactions = res?.data?.transactions ?? [];
  } catch (error) {
    G.log(error);
  }

  return transactions;
};

export const getTransactionDetails = async (txHash: string) => {
  let transactions: any = [];
  try {
    let res = await axios.get(`${G.API_URL}/explorer/transactions/${txHash}`);
    transactions = res?.data?.transactions ?? [];
  } catch (error) {
    G.log(error);
  }

  return transactions;
};

export const getAccountDetails = async (accountAddress: string) => {
  let data: any = {};
  try {
    let res = await axios.get(`${G.API_URL}/explorer/accounts/${accountAddress}`);
    data = res.data;
  } catch (error) {
    G.log(error);
  }

  return data;
};

export const genNewAccount = async () => {
  let data: any = {};
  try {
    let res = await axios.post(`${G.API_URL}/explorer/accounts/new_account`);
    data = res.data;
  } catch (error) {
    console.log(error);
  }

  return data;
};

export const getContractDetails = async (accountAddress: string) => {
  let data: any = {};
  try {
    let res = await axios.post(`${G.API_URL}/explorer/contracts`, {
      accountAddress,
    });
    data = res.data;
  } catch (error) {
    G.log(error);
  }

  return data;
};

export const runContract = async (contractContent: string) => {
  let data: any = {};
  try {
    let res = await axios.post(`${G.API_URL}/explorer/contracts/run`, {
      contractContent,
    });
    data = res.data;
  } catch (error) {
    G.log(error);
  }

  return data;
};

export const searchAccount = async (searchContent: string) => {
  let data: any = {};
  try {
    let res = await axios.get(`${G.API_URL}/explorer/search?content=${searchContent}`);
    data = res.data;
  } catch (error) {
    throw error;
  }

  return data;
};

export const runFunction = async (accountAddress: string, functionName: string, functionParams: any[]) => {
  let data: any = {};
  try {
    let res = await axios.post(`${G.API_URL}/explorer/contracts/execute`, {
      accountAddress,
      functionName,
      functionParams,
    });
    G.log("runFunction res =", res);
    data = res.data;
  } catch (error) {
    throw error;
  }

  return data;
};