import React from 'react';
import { Route, Routes } from 'react-router-dom';

const More: React.FC = () => {
  return (
    <div className="more">
      <h1>Coming Soon</h1>
      <Routes>
        <Route path="technical-guidance" element={<div>Technical Guidance</div>} />
        <Route path="about-us" element={<div>About Us</div>} />
        <Route path="partnerships" element={<div>Partnerships</div>} />
        <Route path="community" element={<div>Community</div>} />
      </Routes>
    </div>
  );
};

export default More;