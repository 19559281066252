import { useMemo } from "react";
import { shortenAddress } from "../utils/methods";
import ClipboardButton from "./ClipboardButton";

interface Transaction {
    id: string;
    txhash: string;
    type: string;
    timestamp: string;
    from: string;
    to: string;
    details: string;
}

const TransactionList = ({ transactionList }: { transactionList: Array<Transaction> }) => {
    const txList = useMemo(() => {
        return transactionList.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
    }, [transactionList])
    return (
        <div className="contract-list mt-10 overflow-scroll">
            <table className="bg-primary-400/80 w-full text-white/85">
                <thead className="bg-primary-400">
                    <tr className="bg-primary-400">
                        <th>No</th>
                        <th>Transaction Hash</th>
                        <th>Type</th>
                        <th>Timestamp</th>
                        <th>From</th>
                        <th>To</th>
                    </tr>
                </thead>
                <tbody>
                    {txList.map((tx, idx) => (
                        <tr key={tx.id}>
                            <td>{idx + 1}</td>
                            <td>
                                <div className="flex gap-2 justify-center">
                                    <a href={`/txn/${tx.txhash}`}>{shortenAddress(tx.txhash, 9)}</a>
                                    <ClipboardButton withoutCopyText={true} content={tx.txhash} />
                                </div>
                            </td>
                            <td>{tx.type}</td>
                            <td>{tx.timestamp}</td>
                            <td>
                                <div className="flex gap-2 justify-center">
                                    <a href={`/accounts/${tx.from}`}>{shortenAddress(tx.from, 4)}</a>
                                    <ClipboardButton withoutCopyText={true} content={tx.from} />
                                </div>
                            </td>
                            <td>
                                <div className="flex gap-2 justify-center">
                                    <a href={`/accounts/${tx.to}`}>{shortenAddress(tx.to, 4)}</a>
                                    <ClipboardButton withoutCopyText={true} content={tx.to} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TransactionList;