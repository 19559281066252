import React, { useEffect, useMemo, useState } from "react";
import CodeInput from "../components/CodeInput";
import Console from "../components/Console";
import { deployCode, uploadCode } from "../services/api";
import CodeView from "../components/CodeView";
import ClipboardButton from "../components/ClipboardButton";
import { EXAMPLES } from "../utils/examples";
import LoadingSpinner from "../components/LoadingSpinner";
import { useSocket } from "../contexts/SocketContext";
import * as G from "../utils/G";

type CompileResult = {
  error?: string;
  result?: string;
  warning?: string;
  abi?: string;
};

export type DeployResult = {
  error?: string;
  isDeployable?: boolean;
  address?: string;
  txHash?: string;
};

const IDE = () => {
  const { socket, isConnected } = useSocket();

  const [codeContent, setCodeContent] = useState("");
  const [compileError, setCompileError] = useState("");
  const [compileWarning, setCompileWarning] = useState("");
  const [abiCode, setAbiCode] = useState("");
  const [hvmCode, setHvmCode] = useState("");

  const [openAccordion, setOpenAccordion] = useState<string | null>(null);

  const [isCompiling, setIsCompiling] = useState(0);
  const [isDeploying, setIsDeploying] = useState(0);
  const [deployResult, setDeployResult] = useState({});

  const [deployId, setDeployId] = useState(0);

  const toggleAccordion = (accordionName: string) => {
    if (openAccordion === accordionName) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(accordionName);
    }
  };

  const onClickCompile = () => {
    setIsCompiling(1);
    uploadCode(codeContent, (result: CompileResult) => {
      setCompileError(result?.error ?? "");
      setCompileWarning(result?.warning ?? "");
      setHvmCode(result?.result ?? "");
      setAbiCode(result?.abi ?? "");
      setIsCompiling(2);
    });
  };

  const onClickDeploy = () => {
    if (isDeploying === 1) return;
    if (!socket || !socket.id) {
      G.log("socket not connected.");
      return;
    }

    // if (compileError) {
    //   alert("Cannot deploy due to compilation error");
    //   return;
    // }

    setIsDeploying(1);
    deployCode(codeContent, deployId, socket.id)
      .then((result: DeployResult) => {
        console.log("deploy result =", result);
        if (result.error) setCompileError(result?.error ?? "");
        setIsDeploying(2);
        setDeployResult(result);
      })
      .catch(() => {
        setIsDeploying(2);
      });
  };

  const onBendSelected = (e: any) => {
    setCodeContent(Object.values(EXAMPLES)[e.target.selectedIndex]);
  };

  useEffect(() => {
    setCodeContent(Object.values(EXAMPLES)[0]);
  }, []);

  useEffect(() => {
    if (isCompiling === 2) {
      if (!compileError) {
        setOpenAccordion("hvm");
      }
    }
  }, [isCompiling, compileError]);

  return (
    <div className="w-full min-h-screen flex items-center flex-col bg-primary-500">
      <div className="w-full flex flex-wrap items-center justify-between border-b border-gray-800 p-1.5 md:p-4">
        <div className="flex-1 sm:w-auto mr-2">
          <select
            id="bend-select"
            className="w-full sm:w-auto bg-primary-400 text-white border border-accent-400 rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-accent-light appearance-none cursor-pointer"
            onChange={(e) => onBendSelected(e)}
          >
            {Object.keys(EXAMPLES).map((k, index) => (
              <option key={index} value={index}>
                {k}
              </option>
            ))}
          </select>
        </div>
        <div className="flex">
          <button
            className="bg-accent hover:bg-accent-600 text-white font-medium py-2 px-3 sm:px-5 rounded-md transition-colors duration-300 mr-2 text-sm sm:text-base"
            onClick={onClickCompile}
          >
            Compile
          </button>
          <button
            className="bg-accent hover:bg-accent-600 text-white font-medium py-2 px-3 sm:px-5 rounded-md transition-colors duration-300 text-sm sm:text-base"
            onClick={onClickDeploy}
            // disabled={!!compileError}
          >
            {isDeploying === 1 ? <LoadingSpinner /> : <span>Deploy</span>}
          </button>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row ">
        <CodeInput setContent={setCodeContent} content={codeContent} />
        <Console
          deployId={deployId}
          isCompiling={isCompiling}
          isDeploying={isDeploying}
          deployResult={deployResult}
          compileError={compileError}
          compileWarning={compileWarning}
        />
      </div>

      <div className="w-full space-y-1 max-h-[calc(40vh-240px)]">
        <div>
          <div
            onClick={() => toggleAccordion("hvm")}
            className={`w-full bg-primary-500 hover:bg-primary-400 text-white p-2 flex justify-between items-center ${
              openAccordion === "hvm" ? "border-l-4 border-accent-500 !bg-primary-400" : ""
            }`}
          >
            <span>HVM</span>
            <svg
              className={`w-5 h-5 transition-transform ${openAccordion === "hvm" ? "transform rotate-180 text-accent" : ""}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
            <ClipboardButton content={hvmCode} />
          </div>
          {openAccordion === "hvm" && (
            <div className="bg-gray-dark">
              <CodeView content={hvmCode} />
            </div>
          )}
        </div>
        <div>
          <div
            onClick={() => toggleAccordion("abi")}
            className={`w-full bg-primary-500 hover:bg-primary-400 text-white p-2 flex justify-between items-center ${
              openAccordion === "abi" ? "border-l-4 border-accent-500 !bg-primary-400" : ""
            }`}
          >
            <span>A B I</span>
            <svg
              className={`w-5 h-5 transition-transform ${openAccordion === "abi" ? " transform rotate-180 text-accent" : ""}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
            <ClipboardButton content={abiCode} />
          </div>
          {openAccordion === "abi" && (
            <div className="bg-gray-dark">
              <CodeView content={abiCode} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IDE;