import React, { createContext, useContext, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import * as G from "../utils/G";

const SocketContext = createContext<{
  socket: Socket | null;
  isConnected: boolean;
}>({ socket: null, isConnected: false });

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const newSocket = io(G.SIO_URL!, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
    });

    setSocket(newSocket);

    const onConnect = () => {
      console.log("Socket connected");
      setIsConnected(true);
    };

    const onDisconnect = (reason: string) => {
      console.log("Socket disconnected:", reason);
      setIsConnected(false);
    };

    const onReconnect = (attemptNumber: number) => {
      console.log("Socket reconnected after", attemptNumber, "attempts");
      setIsConnected(true);
    };

    const onReconnectAttempt = (attemptNumber: number) => {
      console.log("Attempting to reconnect:", attemptNumber);
    };

    const onReconnectError = (error: Error) => {
      console.error("Reconnection error:", error);
    };

    newSocket.on("connect", onConnect);
    newSocket.on("disconnect", onDisconnect);
    newSocket.on("reconnect", onReconnect);
    newSocket.on("reconnect_attempt", onReconnectAttempt);
    newSocket.on("reconnect_error", onReconnectError);

    return () => {
      newSocket.off("connect", onConnect);
      newSocket.off("disconnect", onDisconnect);
      newSocket.off("reconnect", onReconnect);
      newSocket.off("reconnect_attempt", onReconnectAttempt);
      newSocket.off("reconnect_error", onReconnectError);
      newSocket.close();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, isConnected }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);